import { Part } from "./Part";
import { Producer } from "./Producer";
import { AssemblingMachineType, FurnaceType, MiningDrillType, ProducerProfile } from "./ProducerProfile";

export class Material {
	public readonly Parts: Part[];

	public readonly Name: MaterialName;
	public readonly Aliases: string[];
	public readonly CraftTime: number;
	public readonly NumCrafted: number;
	public readonly ProducerType: ProducerType;
	public ImageUrl: string;

	public get IsPiped(): boolean {
		if ((this.Name == "Water") || (this.Name == "Crude Oil") || (this.Name == "Heavy Oil") || (this.Name == "Light Oil") || (this.Name == "Petroleum Gas") || (this.Name == "Sulfuric Acid") || (this.Name == "Lubricant")) {
			return true;
		}
		else {
			return false;
		}
	}

	private constructor(name: MaterialName, craftTime: number, numCrafted: number, producerType: ProducerType, parts: Part[], aliases: string[] = []) {
		this.Name = name;
		this.CraftTime = craftTime;
		this.NumCrafted = numCrafted;
		this.ProducerType = producerType;
		this.Aliases = aliases;
		this.ImageUrl = "";

		this.Parts = [];
		for (let i = 0; i < parts.length; i++) {
			this.Parts.push(parts[i]);
		}

		const depth = this.GetDepth();
		while (Material.TempMaterials.length < (depth + 1)) {
			Material.TempMaterials.push({});
		}
		Material.TempMaterials[depth][name] = this;
	}

	public GetProducer(producerProfile: ProducerProfile): Producer {
		if (this.ProducerType === ProducerType.AssemblingMachine) {
			if (producerProfile.AssemblingMachineType === AssemblingMachineType.AssemblingMachine1) {
				return Producer.AssemblingMachine1;
			} else if (producerProfile.AssemblingMachineType === AssemblingMachineType.AssemblingMachine2) {
				return Producer.AssemblingMachine2;
			} else {
				return Producer.AssemblingMachine3;
			}
		} else if (this.ProducerType === ProducerType.ChemicalPlant) {
			return Producer.ChemicalPlant;
		} else if (this.ProducerType === ProducerType.Furnace) {
			if (producerProfile.FurnaceType === FurnaceType.Stone) {
				return Producer.StoneFurnace;
			} else if (producerProfile.FurnaceType === FurnaceType.Steel) {
				return Producer.SteelFurnace;
			} else {
				return Producer.ElectricFurnace;
			}
		} else if (this.ProducerType === ProducerType.Miner) {
			if (producerProfile.MiningDrillType === MiningDrillType.Burner) {
				return Producer.BurnerMiningDrill;
			} else {
				return Producer.ElectricMiningDrill;
			}
		} else if (this.ProducerType === ProducerType.OffshorePump) {
			return Producer.OffshorePump;
		} else if (this.ProducerType === ProducerType.OilRefinery) {
			return Producer.OilRefinery;
		} else if (this.ProducerType === ProducerType.Pumpjack) {
			return Producer.Pumpjack;
		} else if (this.ProducerType === ProducerType.Centrifuge) {
			return Producer.Centrifuge;
		} else if (this.ProducerType === ProducerType.RocketSilo) {
			return Producer.RocketSilo;
		} else /*if (this.ProducerType === ProducerType.NuclearReactor)*/ {
			return Producer.NuclearReactor;
		}
	}

	private GetDepth(): number {
		let depth = 0;
		for (const part of this.Parts) {
			const tempDepth = part.Material.GetDepth() + 1;
			if (tempDepth > depth) {
				depth = tempDepth;
			}
		}
		return depth;
	}

	private static TempMaterials: Record<string, Material>[];
	private static Materials: Record<string, Material>;

	public static GetMaterial(name: MaterialName): Material {
		if (!Material.Materials) {
			this.initMaterials();
		}
		return Material.Materials[name];
	}

	public static GetAllMaterials(): Material[] {
		if (!Material.Materials) {
			this.initMaterials();
		}

		const returnVal: Material[] = [];
		for (const materialName in Material.Materials) {
			returnVal.push(Material.Materials[materialName]);
		}
		return returnVal;
	}

	private static initMaterials() {
		Material.TempMaterials = [];

		const ironOre = new Material("Iron Ore", 2, 1, ProducerType.Miner, []);
		const copperOre = new Material("Copper Ore", 2, 1, ProducerType.Miner, []);
		const coal = new Material("Coal", 2, 1, ProducerType.Miner, []);
		const stone = new Material("Stone", 2, 1, ProducerType.Miner, []);
		const wood = new Material("Wood", 2, 1, ProducerType.Miner, []);
		const rawFish = new Material("Raw Fish", 2, 1, ProducerType.Miner, []);

		const water = new Material("Water", 1, 1200, ProducerType.OffshorePump, []);
		const crudeOil = new Material("Crude Oil", 1, 10, ProducerType.Pumpjack, []);

		const ironPlate = new Material("Iron Plate", 1.6, 1, ProducerType.Furnace, [new Part(ironOre, 1)]);
		const copperPlate = new Material("Copper Plate", 1.6, 1, ProducerType.Furnace, [new Part(copperOre, 1)]);
		const stoneBrick = new Material("Stone Brick", 1.6, 1, ProducerType.Furnace, [new Part(stone, 2)]);
		const steelPlate = new Material("Steel Plate", 8, 1, ProducerType.Furnace, [new Part(ironPlate, 5)]);

		const petroleumGas = new Material("Petroleum Gas", 5, 55, ProducerType.OilRefinery, [new Part(crudeOil, 55), new Part(water, (55/125))]);
		const lightOil = new Material("Light Oil", 5, 45, ProducerType.OilRefinery, [new Part(crudeOil, 45), new Part(water, (45/125))]);
		const heavyOil = new Material("Heavy Oil", 5, 25, ProducerType.OilRefinery, [new Part(crudeOil, 25), new Part(water, (25/125))]);

		const lubricant = new Material("Lubricant", 1, 10, ProducerType.ChemicalPlant, [new Part(heavyOil, 10)]);
		const sulfur = new Material("Sulfur", 1, 2, ProducerType.ChemicalPlant, [new Part(petroleumGas, 30), new Part(water, 30)]);
		const sulfuricAcid = new Material("Sulfuric Acid", 1, 50, ProducerType.ChemicalPlant, [new Part(ironPlate, 1), new Part(sulfur, 5), new Part(water, 100)]);
		const plastic = new Material("Plastic Bar", 1, 2, ProducerType.ChemicalPlant, [new Part(coal, 1), new Part(petroleumGas, 20)]);

		const copperCable = new Material("Copper Cable", 0.5, 2, ProducerType.AssemblingMachine, [new Part(copperPlate, 1)]);
		const ironGearWheel = new Material("Iron Gear Wheel", 0.5, 1, ProducerType.AssemblingMachine, [new Part(ironPlate, 2)]);
		const ironStick = new Material("Iron Stick", 0.5, 2, ProducerType.AssemblingMachine, [new Part(ironPlate, 1)]);
		const electronicCircuit = new Material("Electronic Circuit", 0.5, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 3), new Part(ironPlate, 1)], ["Green Circuit"]);
		const pipe = new Material("Pipe", 0.5, 1, ProducerType.AssemblingMachine, [new Part(ironPlate, 1)]);
		const advancedCircuit = new Material("Advanced Circuit", 6, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 4), new Part(electronicCircuit, 2), new Part(plastic, 2)], ["Red Circuit"]);
		const battery = new Material("Battery", 4, 1, ProducerType.ChemicalPlant, [new Part(copperPlate, 1), new Part(ironPlate, 1), new Part(sulfuricAcid, 20)]);
		const engineUnit = new Material("Engine Unit", 10, 1, ProducerType.AssemblingMachine, [new Part(ironGearWheel, 1), new Part(pipe, 2), new Part(steelPlate, 1)]);
		const rail = new Material("Rail", 0.5, 2, ProducerType.AssemblingMachine, [new Part(ironStick, 1), new Part(steelPlate, 1), new Part(stone, 1)]);
		const electricFurnace = new Material("Electric Furnace", 5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(steelPlate, 10), new Part(stoneBrick, 10)]);
		const electricEngineUnit = new Material("Electric Engine Unit", 10, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 2), new Part(engineUnit, 1), new Part(lubricant, 15)]);
		const flyingRobotFrame = new Material("Flying Robot Frame", 20, 1, ProducerType.AssemblingMachine, [new Part(battery, 2), new Part(electricEngineUnit, 1), new Part(electronicCircuit, 3), new Part(steelPlate, 1)]);
		const lowDensityStructure = new Material("Low Density Structure", 20, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 20), new Part(plastic, 5), new Part(steelPlate, 2)]);
		const processingUnit = new Material("Processing Unit", 10, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 2), new Part(electronicCircuit, 20), new Part(sulfuricAcid, 5)], ["Blue Circuit"]);

		const concrete = new Material("Concrete", 10, 10, ProducerType.AssemblingMachine, [new Part(ironOre, 1), new Part(stoneBrick, 5), new Part(water, 100)]);
		const refinedConcrete = new Material("Refined Concrete", 15, 10, ProducerType.AssemblingMachine, [new Part(concrete, 20), new Part(ironStick, 8), new Part(steelPlate, 1)]);

		const flamethrowerAmmo = new Material("Flamethrower Ammo", 6, 1, ProducerType.ChemicalPlant, [new Part(crudeOil, 100), new Part(steelPlate, 5)]);
		const flamethrowerTurret = new Material("Flamethrower Turret", 20, 1, ProducerType.AssemblingMachine, [new Part(engineUnit, 5), new Part(ironGearWheel, 15), new Part(pipe, 10), new Part(steelPlate, 30)]);
		const explosives = new Material("Explosives", 4, 2, ProducerType.ChemicalPlant, [new Part(coal, 1), new Part(sulfur, 1), new Part(water, 10)]);
		const landMine = new Material("Land Mine", 5, 4, ProducerType.AssemblingMachine, [new Part(explosives, 2), new Part(steelPlate, 1)]);

		const transportBelt = new Material("Transport Belt", 0.5, 2, ProducerType.AssemblingMachine, [new Part(ironGearWheel, 1), new Part(ironPlate, 1)], ["Yellow Belt"]);
		const fastTransportBelt = new Material("Fast Transport Belt", 0.5, 1, ProducerType.AssemblingMachine, [new Part(ironGearWheel, 5), new Part(transportBelt, 1)], ["Red Belt"]);
		const expressTransportBelt = new Material("Express Transport Belt", 0.5, 1, ProducerType.AssemblingMachine, [new Part(fastTransportBelt, 1), new Part(ironGearWheel, 10), new Part(lubricant, 20)], ["Blue Belt"]);

		const splitter = new Material("Splitter", 1, 2, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 5), new Part(ironPlate, 5), new Part(transportBelt, 4)], ["Yellow Splitter"]);
		const fastSplitter = new Material("Fast Splitter", 2, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 10), new Part(ironGearWheel, 10), new Part(splitter, 1)], ["Red Splitter"]);
		const expressSplitter = new Material("Express Splitter", 2, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 10), new Part(fastSplitter, 1), new Part(ironGearWheel, 10), new Part(lubricant, 80)], ["Blue Splitter"]);

		const undergroundBelt = new Material("Underground Belt", 1, 2, ProducerType.AssemblingMachine, [new Part(ironPlate, 10), new Part(transportBelt, 5)], ["Yellow Underground Belt"]);
		const fastUndergroundBelt = new Material("Fast Underground Belt", 2, 2, ProducerType.AssemblingMachine, [new Part(ironGearWheel, 40), new Part(undergroundBelt, 2)], ["Red Underground Belt"]);
		const expressUndergroundBelt = new Material("Express Underground Belt", 2, 2, ProducerType.AssemblingMachine, [new Part(fastUndergroundBelt, 2), new Part(ironGearWheel, 80), new Part(lubricant, 40)], ["Blue Underground Belt"]);

		const inserter = new Material("Inserter", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 1), new Part(ironGearWheel, 1), new Part(ironPlate, 1)]);

		const wall = new Material("Wall", .5, 1, ProducerType.AssemblingMachine, [new Part(stoneBrick, 5)]);
		const firearmMagazine = new Material("Firearm Magazine", 1, 1, ProducerType.AssemblingMachine, [new Part(ironPlate, 4)], ["Ammo"]);
		const piercingRoundsMagazine = new Material("Piercing Rounds Magazine", 3, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 5), new Part(firearmMagazine, 1), new Part(steelPlate, 1)], ["Ammo"]);
		const grenade = new Material("Grenade", 8, 1, ProducerType.AssemblingMachine, [new Part(coal, 10), new Part(ironPlate, 5)]);

		const efficiencyModule = new Material("Efficiency Module", 15, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(electronicCircuit, 5)]);
		const speedModule = new Material("Speed Module", 15, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(electronicCircuit, 5)]);
		const productivityModule = new Material("Productivity Module", 15, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(electronicCircuit, 5)]);
		const efficiencyModule2 = new Material("Efficiency Module 2", 30, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(processingUnit, 5), new Part(efficiencyModule, 4)]);
		const speedModule2 = new Material("Speed Module 2", 30, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(processingUnit, 5), new Part(speedModule, 4)]);
		const productivityModule2 = new Material("Productivity Module 2", 30, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(processingUnit, 5), new Part(productivityModule, 4)]);
		const efficiencyModule3 = new Material("Efficiency Module 3", 60, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(processingUnit, 5), new Part(efficiencyModule2, 5)]);
		const speedModule3 = new Material("Speed Module 3", 60, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(processingUnit, 5), new Part(speedModule2, 5)]);
		const productivigtyModule3 = new Material("Productivity Module 3", 60, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(processingUnit, 5), new Part(productivityModule2, 5)]);

		const steelChest = new Material("Steel Chest", .5, 1, ProducerType.AssemblingMachine, [new Part(steelPlate, 8)]);

		const activeProviderChest = new Material("Active Provider Chest", .5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 1), new Part(electronicCircuit, 3), new Part(steelChest, 1)]);
		const bufferChest = new Material("Buffer Chest", .5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 1), new Part(electronicCircuit, 3), new Part(steelChest, 1)]);
		const requesterChest = new Material("Requester Chest", .5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 1), new Part(electronicCircuit, 3), new Part(steelChest, 1)]);

		const slowdownCapsule = new Material("Slowdown Capsule", 8, 1, ProducerType.AssemblingMachine, [new Part(coal, 5), new Part(electronicCircuit, 2), new Part(steelPlate, 2)]);
		const poisonCapsule = new Material("Poison Capsule", 8, 1, ProducerType.AssemblingMachine, [new Part(coal, 10), new Part(electronicCircuit, 3), new Part(steelPlate, 3)]);

		const cannonShell = new Material("Cannon Shell", 8, 1, ProducerType.AssemblingMachine, [new Part(explosives, 1), new Part(plastic, 2), new Part(steelPlate, 2)]);
		const explosiveCannonShell = new Material("Explosive Cannon Shell", 8, 1, ProducerType.AssemblingMachine, [new Part(explosives, 2), new Part(plastic, 2), new Part(steelPlate, 2)]);
		const shotgunShells = new Material("Shotgun Shells", 3, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 2), new Part(ironPlate, 2)]);
		const piercingShotgunShells = new Material("Piercing Shotgun Shells", 8, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 5), new Part(shotgunShells, 2), new Part(steelPlate, 2)]);
		const clusterGrenade = new Material("Cluster Grenade", 8, 1, ProducerType.AssemblingMachine, [new Part(explosives, 5), new Part(grenade, 7), new Part(steelPlate, 5)]);

		const solidFuel = new Material("Solid Fuel", 2, 1, ProducerType.ChemicalPlant, [new Part(lightOil, 10)]);
		const rocketFuel = new Material("Rocket Fuel", 30, 1, ProducerType.AssemblingMachine, [new Part(lightOil, 10), new Part(solidFuel, 10)]);

		const laserTurret = new Material("Laser Turret", 20, 1, ProducerType.AssemblingMachine, [new Part(battery, 12), new Part(electronicCircuit, 20), new Part(steelPlate, 20)]);
		const rocket = new Material("Rocket", 8, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 1), new Part(explosives, 1), new Part(ironPlate, 2)]);
		const explosiveRocket = new Material("Explosive Rocket", 8, 1, ProducerType.AssemblingMachine, [new Part(explosives, 2), new Part(rocket, 1)]);

		const defenderCapsule = new Material("Defender Capsule", 8, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 3), new Part(ironGearWheel, 3), new Part(piercingRoundsMagazine, 3)]);
		const distractorCapsule = new Material("Distractor Capsule", 15, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 3), new Part(defenderCapsule, 4)]);
		const destroyerCapsule = new Material("Destroyer Capsule", 15, 1, ProducerType.AssemblingMachine, [new Part(distractorCapsule, 4), new Part(speedModule, 1)]);

		const assemblingMachine1 = new Material("Assembling Machine 1", 0.5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 3), new Part(ironGearWheel, 5), new Part(ironPlate, 9)]);
		const assemblingMachine2 = new Material("Assembling Machine 2", 0.5, 1, ProducerType.AssemblingMachine, [new Part(assemblingMachine1, 1), new Part(electronicCircuit, 3), new Part(ironGearWheel, 5), new Part(steelPlate, 2)]);
		const assemblingMachine3 = new Material("Assembling Machine 3", 0.5, 1, ProducerType.AssemblingMachine, [new Part(assemblingMachine2, 2), new Part(speedModule, 4)]);

		const uraniumOre = new Material("Uranium Ore", 4, 1, ProducerType.Miner, []);
		const uranium238 = new Material("Uranium-238", 12, .993, ProducerType.Centrifuge, [new Part(uraniumOre, 10)]);
		const uranium235 = new Material("Uranium-235", 12, .007, ProducerType.Centrifuge, [new Part(uraniumOre, 10)]);
		const uraniumRoundsMagazine = new Material("Uranium Rounds Magazine", 10, 1, ProducerType.AssemblingMachine, [new Part(piercingRoundsMagazine, 1), new Part(uranium238, 1)]);
		const uraniumCannonShell = new Material("Uranium Cannon Shell", 12, 1, ProducerType.AssemblingMachine, [new Part(cannonShell, 1), new Part(uranium238, 1)]);
		const explosiveUraniumCannonShell = new Material("Explosive Uranium Cannon Shell", 12, 1, ProducerType.AssemblingMachine, [new Part(explosiveCannonShell, 1), new Part(uranium238, 1)]);
		const nuclearFuel = new Material("Nuclear Fuel", 90, 1, ProducerType.Centrifuge, [new Part(rocketFuel, 1), new Part(uranium235, 1)]);

		const radar = new Material("Radar", 0.5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 5), new Part(ironGearWheel, 5), new Part(ironPlate, 10)]);
		const artilleryShell = new Material("Artillery Shell", 15, 1, ProducerType.AssemblingMachine, [new Part(explosiveCannonShell, 4), new Part(explosives, 8), new Part(radar, 1)]);
		const artilleryTurret = new Material("Artillery Turret", 40, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 20), new Part(concrete, 60), new Part(ironGearWheel, 40), new Part(steelPlate, 60)]);
		const artilleryWagon = new Material("Artillery Wagon", 4, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 20), new Part(engineUnit, 64), new Part(ironGearWheel, 10), new Part(pipe, 16), new Part(steelPlate, 40)]);

		const beacon = new Material("Beacon", 15, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 20), new Part(copperCable, 10), new Part(electronicCircuit, 20), new Part(steelPlate, 10)]);

		const automationSciencePack = new Material("Automation Science Pack", 5, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 1), new Part(ironGearWheel, 1)]);
		const logisticSciencePack = new Material("Logistic Science Pack", 6, 1, ProducerType.AssemblingMachine, [new Part(inserter, 1), new Part(transportBelt, 1)]);
		const militarySciencePack = new Material("Military Science Pack", 10, 2, ProducerType.AssemblingMachine, [new Part(piercingRoundsMagazine, 1), new Part(grenade, 1), new Part(wall, 2)]);
		const chemicalSciencePack = new Material("Chemical Science Pack", 24, 2, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 3), new Part(engineUnit, 2), new Part(sulfur, 1)]);
		const productionSciencePack = new Material("Production Science Pack", 21, 3, ProducerType.AssemblingMachine, [new Part(electricFurnace, 1), new Part(productivityModule, 1), new Part(rail, 30)]);
		const utilitySciencePack = new Material("Utility Science Pack", 21, 3, ProducerType.AssemblingMachine, [new Part(flyingRobotFrame, 1), new Part(lowDensityStructure, 3), new Part(processingUnit, 2)]);

		const solarPanel = new Material("Solar Panel", 10, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 5), new Part(electronicCircuit, 15), new Part(steelPlate, 5)]);
		const accumulator = new Material("Accumulator", 10, 1, ProducerType.AssemblingMachine, [new Part(battery, 5), new Part(ironPlate, 2)]);

		const satellite = new Material("Satellite", 5, 1, ProducerType.AssemblingMachine, [new Part(accumulator, 100), new Part(lowDensityStructure, 100), new Part(processingUnit, 100), new Part(radar, 5), new Part(rocketFuel, 50), new Part(solarPanel, 100)]);
		const rocketControlUnit = new Material("Rocket Control Unit", 30, 1, ProducerType.AssemblingMachine, [new Part(processingUnit, 1), new Part(speedModule, 1)]);
		const rocketPart = new Material("Rocket Part", 3, 1, ProducerType.RocketSilo, [new Part(lowDensityStructure, 10), new Part(rocketControlUnit, 10), new Part(rocketFuel, 10)]);
		const spaceSciencePack = new Material("Space Science Pack", 40.33, 1000, ProducerType.RocketSilo, [new Part(rocketPart, 100), new Part(satellite, 1)]);

		const atomicBomb = new Material("Atomic Bomb", 50, 1, ProducerType.AssemblingMachine, [new Part(explosives, 10), new Part(rocketControlUnit, 10), new Part(uranium235, 30)]);

		const pistol = new Material("Pistol", 5, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 5), new Part(ironPlate, 5)]);
		const submachineGun = new Material("Submachine Gun", 10, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 5), new Part(ironGearWheel, 10), new Part(ironPlate, 10)]);
		const shotgun = new Material("Shotgun", 10, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 10), new Part(ironGearWheel, 5), new Part(ironPlate, 15), new Part(wood, 5)]);
		const combatShotgun = new Material("Combat Shotgun", 10, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 10), new Part(ironGearWheel, 5), new Part(steelPlate, 15), new Part(wood, 10)]);
		const rocketLauncher = new Material("Rocket Launcher", 10, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 5), new Part(ironGearWheel, 5), new Part(ironPlate, 5)]);
		const flameThrower = new Material("Flamethrower", 10, 1, ProducerType.AssemblingMachine, [new Part(ironGearWheel, 10), new Part(steelPlate, 5)]);

		const dischargeDefenseRemote = new Material("Discharge Defense Remote", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 1)]);
		const artilleryTargetingRemote = new Material("Artillery Targeting Remote", .5, 1, ProducerType.AssemblingMachine, [new Part(processingUnit, 1), new Part(radar, 1)]);

		const lightArmor = new Material("Light Armor", 3, 1, ProducerType.AssemblingMachine, [new Part(ironPlate, 40)]);
		const heavyArmor = new Material("Heavy Armor", 8, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 100), new Part(steelPlate, 50)]);
		const modularArmor = new Material("Modular Armor", 15, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 30), new Part(steelPlate, 50)]);
		const powerArmor = new Material("Power Armor", 20, 1, ProducerType.AssemblingMachine, [new Part(electricEngineUnit, 20), new Part(processingUnit, 40), new Part(steelPlate, 40)]);
		const powerArmorMK2 = new Material("Power Armor MK2", 25, 1, ProducerType.AssemblingMachine, [new Part(efficiencyModule2, 25), new Part(electricEngineUnit, 40), new Part(lowDensityStructure, 30), new Part(processingUnit, 60), new Part(speedModule2, 25)]);

		const portableSolarPanel = new Material("Portable Solar Panel", 10, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 2), new Part(solarPanel, 1), new Part(steelPlate, 5)]);
		const portableFusionReactor = new Material("Portable Fusion Reactor", 10, 1, ProducerType.AssemblingMachine, [new Part(lowDensityStructure, 50), new Part(processingUnit, 200)]);
		const energyShield = new Material("Energy Shield", 10, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(steelPlate, 10)]);
		const energyShieldMK2 = new Material("Energy Shield MK2", 10, 1, ProducerType.AssemblingMachine, [new Part(energyShield, 10), new Part(lowDensityStructure, 5), new Part(processingUnit, 5)]);
		const personalBattery = new Material("Personal Battery", 10, 1, ProducerType.AssemblingMachine, [new Part(battery, 5), new Part(steelPlate, 10)]);
		const personalBatteryMK2 = new Material("Personal Battery MK2", 10, 1, ProducerType.AssemblingMachine, [new Part(personalBattery, 10), new Part(lowDensityStructure, 5), new Part(processingUnit, 15)]);
		const personalLaserDefense = new Material("Personal Laser Defense", 10, 1, ProducerType.AssemblingMachine, [new Part(laserTurret, 5), new Part(lowDensityStructure, 5), new Part(processingUnit, 20)]);
		const dischargeDefense = new Material("Discharge Defense", 10, 1, ProducerType.AssemblingMachine, [new Part(laserTurret, 10), new Part(processingUnit, 5), new Part(steelPlate, 20)]);
		const beltImmunityEquipment = new Material("Belt Immunity Equipment", 10, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(steelPlate, 10)]);
		const exoSkeleton = new Material("Exoskeleton", 10, 1, ProducerType.AssemblingMachine, [new Part(electricEngineUnit, 30), new Part(processingUnit, 10), new Part(steelPlate, 20)]);
		const personalRoboport = new Material("Personal Roboport", 10, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 10), new Part(battery, 45), new Part(ironGearWheel, 40), new Part(steelPlate, 20)]);
		const personalRoboportMK2 = new Material("Personal Roboport MK2", 20, 1, ProducerType.AssemblingMachine, [new Part(lowDensityStructure, 20), new Part(personalRoboport, 5), new Part(processingUnit, 100)]);
		const nightVision = new Material("Nightvision", 10, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(steelPlate, 10)]);

		const gate = new Material("Gate", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 2), new Part(steelPlate, 2), new Part(wall, 1)]);
		const gunTurret = new Material("Gun Turret", 8, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 10), new Part(ironGearWheel, 10), new Part(ironPlate, 20)]);
		const rocketSilo = new Material("Rocket Silo", 30, 1, ProducerType.AssemblingMachine, [new Part(concrete, 1000), new Part(electricEngineUnit, 200), new Part(pipe, 100), new Part(processingUnit, 200), new Part(steelPlate, 1000)]);

		const woodenChest = new Material("Wooden Chest", .5, 2, ProducerType.AssemblingMachine, [new Part(wood, 2)]);
		const ironChest = new Material("Iron Chest", .5, 1, ProducerType.AssemblingMachine, [new Part(ironPlate, 8)]);
		const storageTank = new Material("Storage Tank", 3, 1, ProducerType.AssemblingMachine, [new Part(ironPlate, 20), new Part(steelPlate, 5)]);

		const burnerInserter = new Material("Burner Inserter", .5, 1, ProducerType.AssemblingMachine, [new Part(ironGearWheel, 1), new Part(ironPlate, 1)]);
		const longHandedInserter = new Material("Long-Handed Inserter", .5, 1, ProducerType.AssemblingMachine, [new Part(inserter, 1), new Part(ironGearWheel, 1), new Part(ironPlate, 1)]);
		const fastInserter = new Material("Fast Inserter", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 2), new Part(inserter, 1), new Part(ironPlate, 2)]);
		const filterInserter = new Material("Filter Inserter", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 4), new Part(fastInserter, 1)]);
		const stackInserter = new Material("Stack Inserter", .5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 1), new Part(electronicCircuit, 15), new Part(fastInserter, 1), new Part(ironGearWheel, 15)]);

		const smallElectricPole = new Material("Small Electric Pole", .5, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 2), new Part(wood, 1)]);
		const mediumElectricPole = new Material("Medium Electric Pole", .5, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 2), new Part(ironStick, 4), new Part(steelPlate, 2)]);
		const bigElectricPole = new Material("Big Electric Pole", .5, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 5), new Part(ironStick, 8), new Part(steelPlate, 5)]);
		const substation = new Material("Substation", .5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 5), new Part(copperPlate, 5), new Part(steelPlate, 10)]);
		const pipeToGround = new Material("Pipe To Ground", .5, 2, ProducerType.AssemblingMachine, [new Part(ironPlate, 5), new Part(pipe, 10)]);
		const pump = new Material("Pump", 2, 1, ProducerType.AssemblingMachine, [new Part(engineUnit, 1), new Part(pipe, 1), new Part(steelPlate, 1)]);

		const trainStop = new Material("Train Stop", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 5), new Part(ironPlate, 6), new Part(ironStick, 6), new Part(steelPlate, 3)]);
		const railSignal = new Material("Rail Signal", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 1), new Part(ironPlate, 5)]);
		const railChainSignal = new Material("Rail Chain Signal", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 1), new Part(ironPlate, 5)]);
		const locomotive = new Material("Locomotive", 4, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 10), new Part(engineUnit, 20), new Part(steelPlate, 30)]);
		const cargoWagon = new Material("Cargo Wagon", 1, 1, ProducerType.AssemblingMachine, [new Part(ironGearWheel, 10), new Part(ironPlate, 20), new Part(steelPlate, 20)]);
		const fluidWagon = new Material("Fluid Wagon", 1.5, 1, ProducerType.AssemblingMachine, [new Part(ironGearWheel, 10), new Part(pipe, 8), new Part(steelPlate, 16), new Part(storageTank, 1)]);
		const car = new Material("Car", 2, 1, ProducerType.AssemblingMachine, [new Part(engineUnit, 8), new Part(ironPlate, 20), new Part(steelPlate, 5)]);
		const tank = new Material("Tank", 5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 10), new Part(engineUnit, 32), new Part(ironGearWheel, 15), new Part(steelPlate, 50)]);
		const spidertron = new Material("Spidertron", 10, 1, ProducerType.AssemblingMachine, [new Part(efficiencyModule3, 2), new Part(exoSkeleton, 4), new Part(portableFusionReactor, 2), new Part(lowDensityStructure, 150), new Part(radar, 2), new Part(rawFish, 1), new Part(rocketControlUnit, 16), new Part(rocketLauncher, 4)]);
		const spidertronRemote = new Material("Spidertron Remote", .5, 1, ProducerType.AssemblingMachine, [new Part(radar, 1), new Part(rocketControlUnit, 1)]);

		const logisticRobot = new Material("Logistic Robot", .5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 2), new Part(flyingRobotFrame, 1)]);
		const constructionRobot = new Material("Construction Robot", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 2), new Part(flyingRobotFrame, 1)]);
		const passiveProviderChest = new Material("Passive Provider Chest", .5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 1), new Part(electronicCircuit, 3), new Part(steelChest, 1)]);
		const storageChest = new Material("Storage Chest", .5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 1), new Part(electronicCircuit, 1), new Part(steelChest, 1)]);
		const roboport = new Material("Roboport", 5, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 45), new Part(ironGearWheel, 45), new Part(steelPlate, 45)]);

		const lamp = new Material("Lamp", .5, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 3), new Part(electronicCircuit, 1), new Part(ironPlate, 1)]);
		const redWire = new Material("Red Wire", .5, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 1), new Part(electronicCircuit, 1)]);
		const greenWire = new Material("Green Wire", .5, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 1), new Part(electronicCircuit, 1)]);
		const arithmeticCombinator = new Material("Arithmetic Combinator", .5, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 5), new Part(electronicCircuit, 5)]);
		const deciderCombinator = new Material("Decider Combinator", .5, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 5), new Part(electronicCircuit, 5)]);
		const constantCombinator = new Material("Constant Combinator", .5, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 5), new Part(electronicCircuit, 2)]);
		const powerSwitch = new Material("Power Switch", 2, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 5), new Part(electronicCircuit, 2), new Part(ironPlate, 5)]);
		const programmableSpeaker = new Material("Programmable Speaker", 2, 1, ProducerType.AssemblingMachine, [new Part(copperCable, 5), new Part(electronicCircuit, 4), new Part(ironPlate, 3), new Part(ironStick, 4)]);

		const emptyBarrel = new Material("Empty Barrel", 1, 1, ProducerType.AssemblingMachine, [new Part(steelPlate, 1)]);

		const hazardConcrete = new Material("Hazard Concrete", .25, 10, ProducerType.AssemblingMachine, [new Part(concrete, 10)]);
		const refinedHazardConcrete = new Material("Refined Hazard Concrete", .25, 10, ProducerType.AssemblingMachine, [new Part(refinedConcrete, 10)]);
		const landFill = new Material("Landfill", .5, 1, ProducerType.AssemblingMachine, [new Part(stone, 20)]);
		const cliffExplosives = new Material("Cliff Explosives", 8, 1, ProducerType.AssemblingMachine, [new Part(emptyBarrel, 1), new Part(explosives, 10), new Part(grenade, 1)]);

		const stoneFurnace = new Material("Stone Furnace", .5, 1, ProducerType.AssemblingMachine, [new Part(stone, 5)]);
		const steelFurnace = new Material("Steel Furnace", 3, 1, ProducerType.AssemblingMachine, [new Part(steelPlate, 6), new Part(stoneBrick, 10)]);

		const repairPack = new Material("Repair Pack", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 2), new Part(ironGearWheel, 2)]);
		const boiler = new Material("Boiler", .5, 1, ProducerType.AssemblingMachine, [new Part(pipe, 4), new Part(stoneFurnace, 1)]);
		const steamEngine = new Material("Steam Engine", .5, 1, ProducerType.AssemblingMachine, [new Part(ironGearWheel, 8), new Part(ironPlate, 10), new Part(pipe, 5)]);
		const steamTurbine = new Material("Steam Turbine", 3, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 50), new Part(ironGearWheel, 50), new Part(pipe, 20)]);
		const nuclearReactor = new Material("Nuclear Reactor", 8, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 500), new Part(concrete, 500), new Part(copperPlate, 500), new Part(steelPlate, 500)]);
		const heatExchanger = new Material("Heat Exchanger", 3, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 100), new Part(pipe, 10), new Part(steelPlate, 10)]);
		const heatPipe = new Material("Heat Pipe", 1, 1, ProducerType.AssemblingMachine, [new Part(copperPlate, 20), new Part(steelPlate, 10)]);

		const burnerMiningDrill = new Material("Burner Mining Drill", 2, 1, ProducerType.AssemblingMachine, [new Part(ironGearWheel, 3), new Part(ironPlate, 3), new Part(stoneFurnace, 1)]);
		const electricMiningDrill = new Material("Electric Mining Drill", 2, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 3), new Part(ironGearWheel, 5), new Part(ironPlate, 10)]);
		const offshorePump = new Material("Offshore Pump", .5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 2), new Part(ironGearWheel, 1)]);
		const pumpjack = new Material("Pumpjack", 5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 5), new Part(ironGearWheel, 10), new Part(pipe, 10), new Part(steelPlate, 5)]);

		const oilRefinery = new Material("Oil Refinery", 8, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 10), new Part(ironGearWheel, 10), new Part(pipe, 10), new Part(steelPlate, 15), new Part(stoneBrick, 10)]);
		const chemicalPlant = new Material("Chemical Plant", 5, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 5), new Part(ironGearWheel, 5), new Part(pipe, 5), new Part(steelPlate, 5)]);
		const centrifuge = new Material("Centrifuge", 4, 1, ProducerType.AssemblingMachine, [new Part(advancedCircuit, 100), new Part(concrete, 100), new Part(ironGearWheel, 100), new Part(steelPlate, 50)]);
		const lab = new Material("Lab", 2, 1, ProducerType.AssemblingMachine, [new Part(electronicCircuit, 10), new Part(ironGearWheel, 10)]);

		const uraniumFuelCell = new Material("Uranium Fuel Cell", 10, 10, ProducerType.Centrifuge, [new Part(ironPlate, 10), new Part(uranium235, 1), new Part(uranium238, 19)]);
		const usedUpUraniumFuelCell = new Material("Used Up Uranium Fuel Cell", 200, 1, ProducerType.NuclearReactor, [new Part(uraniumFuelCell, 1)]);

		Material.Materials = {};
		for (let i = 0; i < Material.TempMaterials.length; i++) {
			let toSort = [];
			for (const name in Material.TempMaterials[i]) {
				toSort.push((Material.TempMaterials[i][name].IsPiped ? "BB " : "AA ") + name);
			}
			toSort = toSort.sort();
			for (const name of toSort) {
				const realName = name.substring(3);
				Material.Materials[realName] = Material.TempMaterials[i][realName];
			}
		}

		// Loop over all of the materials here and set the image url based on what it probably is, using the name:
		// example: "https://wiki.factorio.com/images/thumb/Transport_belt.png/24px-Transport_belt.png"
		for (const name in Material.Materials) {
			const material = Material.Materials[name];
			let urlName: string = material.Name;
			if (material.Name === "Rail") {
				urlName = "Straight Rail"; // There's no "rail" image, but there's a "straight rail" image
			}
			urlName = urlName.replace(/ /g, "_");
			if (urlName.indexOf("_MK2") !== -1) {
				urlName = urlName.substring(0, 1) + urlName.substring(1, urlName.indexOf("_MK2")).toLowerCase() + "_MK2";
			} else {
				urlName = urlName.substring(0, 1) + urlName.substring(1, urlName.length).toLowerCase();
			}
			material.ImageUrl = "https://wiki.factorio.com/images/thumb/" + urlName + ".png/24px-" + urlName + ".png";
		}
	}
}

export type MaterialName =
	"Oil Refinery" | "Chemical Plant" | "Centrifuge" | "Lab" | "Uranium Fuel Cell" | "Used Up Uranium Fuel Cell" |
	"Burner Mining Drill" | "Electric Mining Drill" | "Offshore Pump" | "Pumpjack" |
	"Repair Pack" | "Stone Furnace" | "Steel Furnace" | "Boiler" | "Steam Engine" | "Steam Turbine" | "Nuclear Reactor" | "Heat Exchanger" | "Heat Pipe" |
	"Empty Barrel" | "Hazard Concrete" | "Refined Hazard Concrete" | "Landfill" | "Cliff Explosives" |
	"Lamp" | "Red Wire" | "Green Wire" | "Arithmetic Combinator" | "Decider Combinator" | "Constant Combinator" | "Power Switch" | "Programmable Speaker" |
	"Logistic Robot" | "Construction Robot" | "Passive Provider Chest" | "Storage Chest" | "Roboport" |
	"Raw Fish" | "Train Stop" | "Rail Signal" | "Rail Chain Signal" | "Locomotive" | "Cargo Wagon" | "Fluid Wagon" | "Car" | "Tank" | "Spidertron" | "Spidertron Remote" |
	"Small Electric Pole" | "Medium Electric Pole" | "Big Electric Pole" | "Substation" | "Pipe To Ground" | "Pump" |
	"Burner Inserter" | "Long-Handed Inserter" | "Fast Inserter" | "Filter Inserter" | "Stack Inserter" |
	"Wooden Chest" | "Iron Chest" | "Storage Tank" |
	"Pistol" | "Submachine Gun" | "Shotgun" | "Wood" | "Combat Shotgun" | "Rocket Launcher" | "Flamethrower" |
	"Discharge Defense Remote" | "Artillery Targeting Remote" |
	"Light Armor" | "Heavy Armor" | "Modular Armor" | "Power Armor" | "Power Armor MK2" |
	"Portable Solar Panel" | "Portable Fusion Reactor" | "Energy Shield" | "Energy Shield MK2" | "Personal Battery" | "Personal Battery MK2" | "Personal Laser Defense" | "Discharge Defense" | "Belt Immunity Equipment" | "Exoskeleton" | "Personal Roboport" | "Personal Roboport MK2" | "Nightvision" |
	"Gate" | "Gun Turret" |
	"Solar Panel" | "Accumulator" | "Satellite" | "Rocket Control Unit" | "Rocket Part" | "Space Science Pack" |
	"Radar" | "Artillery Shell" | "Artillery Turret" | "Artillery Wagon" |
	"Iron Ore" | "Copper Ore" | "Coal" | "Stone" | "Shotgun Shells" | "Piercing Shotgun Shells" | "Cluster Grenade" | "Solid Fuel" | "Rocket Fuel" | "Laser Turret" | "Rocket" | "Explosive Rocket" |
	"Uranium Ore" | "Uranium-238" | "Uranium-235" | "Uranium Rounds Magazine" |
	"Steel Chest" | "Active Provider Chest" | "Buffer Chest" | "Requester Chest" | "Slowdown Capsule" | "Poison Capsule" | "Cannon Shell" | "Explosive Cannon Shell" |
	"Light Oil" | "Uranium Cannon Shell" | "Explosive Uranium Cannon Shell" |
	"Defender Capsule" | "Distractor Capsule" | "Destroyer Capsule" |
	"Water" | "Crude Oil" | "Nuclear Fuel" | "Beacon" |
	"Iron Plate" | "Copper Plate" | "Stone Brick" | "Steel Plate" |
	"Petroleum Gas" | "Heavy Oil" | "Lubricant" | "Sulfur" | "Sulfuric Acid" | "Plastic Bar" |
	"Copper Cable" | "Iron Gear Wheel" | "Iron Stick" | "Electronic Circuit" | "Pipe" | "Advanced Circuit" | "Battery" | "Engine Unit" | "Rail" | "Electric Furnace" | "Electric Engine Unit" | "Flying Robot Frame" | "Low Density Structure" | "Processing Unit" |
	"Assembling Machine 1" | "Assembling Machine 2" | "Assembling Machine 3" |
	"Concrete" | "Refined Concrete" |
	"Inserter" | "Rocket Silo" |
	"Flamethrower Ammo" | "Flamethrower Turret" | "Explosives" | "Land Mine" | "Wall" | "Firearm Magazine" | "Piercing Rounds Magazine" | "Grenade" |
	"Transport Belt" | "Fast Transport Belt" | "Express Transport Belt" | "Splitter" | "Fast Splitter" | "Express Splitter" | "Underground Belt" | "Fast Underground Belt" | "Express Underground Belt" |
	"Efficiency Module" | "Speed Module" | "Productivity Module" | "Efficiency Module 2" | "Speed Module 2" | "Productivity Module 2" | "Efficiency Module 3" | "Speed Module 3" | "Productivity Module 3" |
	"Automation Science Pack" | "Logistic Science Pack" | "Military Science Pack" | "Chemical Science Pack" | "Production Science Pack" | "Utility Science Pack" |
	"Atomic Bomb"
	;

export enum ProducerType {
	Miner,
	Pumpjack,
	OffshorePump,
	Furnace,
	AssemblingMachine,
	OilRefinery,
	ChemicalPlant,
	Centrifuge,
	RocketSilo,
	NuclearReactor,
}