<template>
	<div class="dropdown">
		<div v-if="valueWrapper" @click="resetSelection" class="dropdown-selected" tabindex="0" v-on:keydown.enter="resetSelection" ref="selectedDisplay">
			<img v-if="valueWrapper.imageUrl" :src="modelValue.imageUrl" class="dropdown-item-flag" />
			<span class="selected-item-name">{{valueWrapper.name}}</span>
		</div>
		<template v-else>
			<input type="text" :placeholder="searchText" v-model.trim="inputValue" class="dropdown-input" ref="dropDownInput" @focus="inputFocused" @blur="inputUnfocused" v-on:keydown="inputBoxKeyDown" />
			<div v-show="inputIsFocused" class="dropdown-list" v-click-outside="inputUnfocused">
				<ul class="materials-list">
					<li v-for="(item, index) in items" :key="item.id" @click="selectItem(item)" v-show="isItemVisible(item)" class="dropdown-item" :tabindex="9999 + index" :ref="setListItemRef" @blur="inputUnfocused" v-on:keydown="listKeyDown">
						<img v-if="item.imageUrl" :src="item.imageUrl" class="dropdown-item-flag" />
						{{item.name}}
					</li>
				</ul>
			</div>
		</template>
	</div>
</template>

<script>

	export default {
		name: 'SearchableDropdown',
		data: function () {
			return {
				inputValue: "",
				inputIsFocused: false,
				liRefs: [],
			};
		},
		props: {
			items: Array,
			modelValue: Object,
			searchText: String,
		},
		methods: {
			setListItemRef(el) {
				if (el) {
					this.liRefs.push(el);
				}
			},
			resetSelection() {
				this.valueWrapper = null;
				this.$nextTick(() => this.$refs.dropDownInput.focus());
			},
			selectItem(item) {
				this.valueWrapper = item;
				this.inputValue = "";
				this.$nextTick(() => this.$refs.selectedDisplay.focus());
			},
			isItemVisible(item) {
				let searchVals = [];
				searchVals.push(item.name);
				searchVals = searchVals.concat(item.aliases);
				for (let val of searchVals) {
					if (val.toLowerCase().includes(this.inputValue.toLowerCase())) {
						return true;
					}
				}
				return false;
			},
			inputFocused() {
				this.inputIsFocused = true;
			},
			inputUnfocused() {		
				// Unfortunately, activeElement isn't set in some browsers until after the unfocus has occurred, and I can't figure out a better way to solve this than just by waiting a short period of time before checking:
				let self = this;
				setTimeout(function () {
					// If the input is focused, then leave the popup up:
					if (self.$refs.dropDownInput === document.activeElement) {
						return;
					}
					// Otherwise, if one of the list items is focused, leave the popup up:
					for (let listItem of self.liRefs) {
						if (listItem === document.activeElement) {
							return;
						}
					}
					// Otherwise, we need to close the popup:
					self.inputIsFocused = false;
				}, 1);
			},
			setFocusOnList() {
				this.setFocusOnNextItem(-1);
			},
			inputBoxKeyDown(event) {
				if (event.key === "ArrowDown") {
					this.setFocusOnList();
					event.preventDefault();
				}
			},
			listKeyDown(event) {
				if (event.key === "ArrowDown") {
					this.setFocusOnNextItem(this.getCurrentFocusIndex());
					event.preventDefault();
				} else if (event.key === "ArrowUp") {
					this.setFocusOnPreviousItem(this.getCurrentFocusIndex());
					event.preventDefault();
				} else if (event.key === "Enter") {
					let currentFocusIndex = this.getCurrentFocusIndex();
					if (currentFocusIndex !== -1) {
						this.selectItem(this.items[currentFocusIndex]);
					}
				} else {
					// TODO: Temp, change this:
					this.$refs.dropDownInput.focus();
					this.$refs.dropDownInput.select();
				}
			},
			setFocusOnNextItem(currItemIndex) {
				if (this.items) {
					for (let i = currItemIndex + 1; i < this.items.length; i++) {
						if (this.isItemVisible(this.items[i])) {
							this.liRefs[i].focus();
							return;
						}
					}
				}
			},
			setFocusOnPreviousItem(currItemIndex) {
				if (this.items) {
					for (let i = currItemIndex - 1; i >= 0; i--) {
						if (this.isItemVisible(this.items[i])) {
							this.liRefs[i].focus();
							return;
						}
					}
				}
			},
			getCurrentFocusIndex() {
				for (let i = 0; i < this.liRefs.length; i++) {
					if (this.liRefs[i] === document.activeElement) {
						return i;
					}
				}
				return -1;
			},
		},
		computed: {
			valueWrapper: {
				get: function () {
					return this.modelValue;
				},
				set: function (newValue) {
					this.$emit('update:modelValue', newValue);
				},
			},
		},
		beforeUpdate() {
			this.liRefs = [];
		},
	}
</script>

<style scoped>
	.dropdown {
		position: relative;
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		min-width: 1px;
	}

	.dropdown-input, .dropdown-selected {
		width: 100%;
		padding: 1px 3px;
		border: 1px solid #888888;
		border-radius: 2px;
	}

	.dropdown-selected {
		cursor: pointer;
		background-color: #eeeeee;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.selected-item-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.dropdown-list {
		padding: 3px;
		position: absolute;
		width: 100%;
		max-height: 500px;
		overflow-y: auto;
		background: #f9f9f9;
		box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
		border: 1px solid #888888;
		border-radius: 2px;
		z-index: 9999;
	}

	.dropdown-item {
		display: flex;
		width: 100%;
		padding: 0;
		cursor: pointer;
		outline: none;
	}

		.dropdown-item:hover, .dropdown-item:focus {
			background: #edf2f7;
		}

	.dropdown-item-flag {		
		max-width: 24px;
		max-height: 24px;
		margin-right: 6px;
	}

	.materials-list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		outline: none;
	}
</style>