import { AssemblyArea } from "./AssemblyArea";
import { Factory } from "./Factory";
import { MaterialQuantity } from "./MaterialQuantity";
import { ProducerProfile } from "./ProducerProfile";

export class FactoryReporter {
	public static Report(factory: Factory): string {
		let returnVal = "";

		const assemblyAreas = factory.ComputeAssemblyAreas();

		if ((assemblyAreas.length > 0) && (assemblyAreas[0].TotalInputs.length > 0)) {
			returnVal += this.GetHeading("Inputs");
			returnVal += this.StartTable();
			for (const quantity of assemblyAreas[0].TotalInputs) {
				returnVal += this.WriteInput(quantity, 0);
			}
			returnVal += this.EndTable();
		}

		for (let i = 0; i < assemblyAreas.length; i++)
		{
			const area = assemblyAreas[i];
			returnVal += this.GetHeading(area.Name + " (Assembly Area #" + (i + 1) + ")");
			returnVal += this.WriteAssemblyAreaReport(area, (i === (assemblyAreas.length - 1)), factory.ProducerProfile);
		}

		return returnVal;
	}

	private static WriteAssemblyAreaReport(area: AssemblyArea, isFinal: boolean, producerProfile: ProducerProfile): string {
		let returnVal = "";

		returnVal += this.GetSubHeading("Onsite Production:");
		returnVal += this.StartTable();
		for(const quantity of area.Outputs)
		{
			returnVal += this.WriteOnsiteProduction(quantity, area, producerProfile, 0);
		}
		returnVal += this.EndTable();

		let first = true;
		const shippedIn = area.GetShippedIn();
		for(const quantity of shippedIn)
		{
			if (first) {
				returnVal += this.GetSubHeading("Shipped In:");
				returnVal += this.StartTable();
			}
			first = false;
			returnVal += this.WriteShippedIn(quantity, 0);
		}
		if (!first) {
			returnVal += this.EndTable();
		}

		if (!isFinal) {
			first = true;
			for (const quantity of area.Outputs) {
				if (first) {
					returnVal += this.GetSubHeading("Belt Status After This:");
					returnVal += this.StartTable();
				}
				first = false;
				returnVal += this.WriteShippedThrough(quantity, 0);
			}
			for (const quantity of area.ShippedThrough) {
				if (first) {
					returnVal += this.GetSubHeading("Belt Status After This:");
					returnVal += this.StartTable();
				}
				first = false;
				returnVal += this.WriteShippedThrough(quantity, 0);
			}
			if (!first) {
				returnVal += this.EndTable();
			}
		}

		return returnVal;
	}

	private static StartTable(): string {
		return "<table><tr><th>Material Name</th><th>Throughput</th><th>Factories Needed</th><th>Yellow Belts</th><th>Red Belts</th><th>Blue Belts</th></tr>";
	}

	private static EndTable(): string {
		return "</table>";
	}

	private static GetHeading(value: string): string {
		return "<h3>" + value + "</h3>";
	}


	private static GetSubHeading(value: string): string {
		return "<h4>" + value + "</h4>";
	}

	private static WriteOnsiteProduction(quantity: MaterialQuantity, area: AssemblyArea, producerProfile: ProducerProfile, level: number): string {
		let returnVal = "";
		if (area.IsInput(quantity.Material)) {
			returnVal += this.WriteShippedIn(quantity, level);
		}
		else {
			returnVal += this.WriteProduced(quantity, producerProfile, level);
			for(const preReq of quantity.PreReqs)
			{
				returnVal += this.WriteOnsiteProduction(preReq, area, producerProfile, level + 1);
			}
		}
		return returnVal;
	}

	private static WriteProduced(quantity: MaterialQuantity, producerProfile: ProducerProfile, level: number): string {
		return "<tr><td><span class=\"level" + level + "\">" + quantity.Material.Name + "</span></td><td>"
			+ this.RoundIt(quantity.NumPerSec) + "/sec" + "</td><td>"
			+ quantity.GetNumFactories(producerProfile) + " " + quantity.Material.GetProducer(producerProfile).PluralName + "</td><td>"
			+ this.BeltString(quantity, 1) + "</td><td>"
			+ this.BeltString(quantity, 2) + "</td><td>"
			+ this.BeltString(quantity, 3) + "</td></tr>";
	}

	private static WriteShippedIn(quantity: MaterialQuantity, level: number): string {
		return "<tr><td><span class=\"level" + level + "\">" + quantity.Material.Name + "</span></td><td>"
			+ this.RoundIt(quantity.NumPerSec) + "/sec" + "</td><td>"
			+ "(Shipped in)" + "</td><td>"
			+ this.BeltString(quantity, 1) + "</td><td>"
			+ this.BeltString(quantity, 2) + "</td><td>"
			+ this.BeltString(quantity, 3) + "</td></tr>";
	}

	private static WriteShippedThrough(quantity: MaterialQuantity, level: number): string {
		return "<tr><td><span class=\"level" + level + "\">" + quantity.Material.Name + "</span></td><td>"
			+ this.RoundIt(quantity.NumPerSec) + "/sec" + "</td><td>"
			+ "" + "</td><td>"
			+ this.BeltString(quantity, 1) + "</td><td>"
			+ this.BeltString(quantity, 2) + "</td><td>"
			+ this.BeltString(quantity, 3) + "</td></tr>";
	}

	private static WriteInput(quantity: MaterialQuantity, level: number): string {
		return "<tr><td><span class=\"level" + level + "\">" + quantity.Material.Name + "</span></td><td>"
			+ this.RoundIt(quantity.NumPerSec) + "/sec" + "</td><td>"
			+ "" + "</td><td>"
			+ this.BeltString(quantity, 1) + "</td><td>"
			+ this.BeltString(quantity, 2) + "</td><td>"
			+ this.BeltString(quantity, 3) + "</td></tr>";
	}

	private static RoundIt(num: number): string {
		return (Math.round(num * 100) / 100).toString();
	}

	private static BeltString(quantity: MaterialQuantity, beltLevel: number): string {
		if (quantity.Material.IsPiped) {
			if ((beltLevel == 2) || (beltLevel == 3)) {
				return "";
			}
			else {
				return quantity.NumPipes + (quantity.NumPipes == 1 ? " pipe" : " pipes");
			}
		}
		else if (beltLevel == 3) {
			return quantity.NumExpressBelts + (quantity.NumExpressBelts == 1 ? " belt" : " belts");
		}
		else if (beltLevel == 2) {
			return quantity.NumFastBelts + (quantity.NumFastBelts == 1 ? " belt" : " belts");
		}
		else {
			return quantity.NumBasicBelts + (quantity.NumBasicBelts == 1 ? " belt" : " belts");
		}
	}
}