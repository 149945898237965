export class ProducerProfile {
	public MiningDrillType!: MiningDrillType;
	public FurnaceType!: FurnaceType;
	public AssemblingMachineType!: AssemblingMachineType;
}

export enum MiningDrillType {
	Burner,
	Electric,
}

export enum FurnaceType {
	Stone,
	Steel,
	Electric,
}

export enum AssemblingMachineType {
	AssemblingMachine1,
	AssemblingMachine2,
	AssemblingMachine3,
}