<template>
	<div>
		<h1>Factorio Factory Planner (Beta)</h1>
		<div class="row">
			<div class="col-sm">
				<h2>Machine Types</h2>
				<div class="section-description">The types of assembling machines and furnaces to use.</div>
				<div class="producer-types-div">
					<select v-model="assemblingMachineType">
						<option>Assembling Machine 1</option>
						<option>Assembling Machine 2</option>
						<option>Assembling Machine 3</option>
					</select>
					<select v-model="furnaceType">
						<option>Stone Furnace</option>
						<option>Steel Furnace</option>
						<option>Electric Furnace</option>
					</select>
					<select v-model="miningDrillType">
						<option>Burner Mining Drill</option>
						<option>Electric Mining Drill</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm">
				<h2>Inputs</h2>
				<div class="section-description">Products created offsite and shipped in.</div>
				<material-list-selector v-model="inputMaterials" :allMaterials="allMaterials"></material-list-selector>
			</div>
			<div class="col-sm">
				<h2>Intermediate Areas</h2>
				<div class="section-description">Onsite areas dedicated to intermediate products.</div>
				<intermediates v-model="intermediateAreas" :allMaterials="allMaterials"></intermediates>
			</div>
		</div>
		<div class="row">
			<div class="col-sm">
				<h2>Outputs</h2>
				<div class="section-description">Products to create.</div>
				<material-list-selector v-model="outputMaterials"
										:allMaterials="allMaterials"
										:editOutputs="true"
										:defaultAssemblingMachineType="assemblingMachineType"
										:defaultFurnaceType="furnaceType"
										:defaultMiningDrillType="miningDrillType">
				</material-list-selector>
			</div>
		</div>
		<div class="row">
			<div class="col-sm">
				<h2>Factory Plan</h2>
				<div class="controlsDiv">
					<button id="generateReport" @click="showFactoryPlan">Create My Plan!</button>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm" v-html="reportText">
			</div>
		</div>
	</div>
</template>

<script>
	import { Factory } from "@/logic/FactoryPlanner/Factory";
	import { Material } from "@/logic/FactoryPlanner/Material";
	import { FactoryReporter } from "@/logic/FactoryPlanner/FactoryReporter";
	import { AssemblingMachineType, FurnaceType, MiningDrillType } from "@/logic/FactoryPlanner/ProducerProfile";
	import MaterialListSelector from "@/components/FactoryPlanner/MaterialListSelector.vue";
	import Intermediates from "@/components/FactoryPlanner/Intermediates.vue";

	export default {
		name: 'FactoryPlanner',
		components: {
			'material-list-selector': MaterialListSelector,
			'intermediates': Intermediates,
		},
		data: function () {
			return {
				reportText: "",
				allMaterials: Material.GetAllMaterials(),
				dropdownSelection: {},
				inputMaterials: [
					{ id: "a", material: Material.GetMaterial("Iron Ore") },
					{ id: "b", material: Material.GetMaterial("Copper Ore") },
					{ id: "c", material: Material.GetMaterial("Stone") },
					{ id: "d", material: Material.GetMaterial("Coal") },
					{ id: "e", material: Material.GetMaterial("Wood") },
					{ id: "f", material: Material.GetMaterial("Uranium Ore") },
					{ id: "g", material: Material.GetMaterial("Water") },
					{ id: "h", material: Material.GetMaterial("Crude Oil") },
					{ id: "i", material: Material.GetMaterial("Iron Plate") },
					{ id: "j", material: Material.GetMaterial("Copper Plate") },
					{ id: "k", material: Material.GetMaterial("Stone Brick") },
					{ id: "l", material: Material.GetMaterial("Steel Plate") },
					{ id: "m", material: Material.GetMaterial("Petroleum Gas") },
					{ id: "n", material: Material.GetMaterial("Light Oil") },
					{ id: "o", material: Material.GetMaterial("Heavy Oil") },
					{ id: "p", material: Material.GetMaterial("Uranium-235") },
					{ id: "q", material: Material.GetMaterial("Uranium-238") },
					{ id: "r", material: Material.GetMaterial("Raw Fish") },
				],
				intermediateAreas: [],
				outputMaterials: [],
				assemblingMachineType: "Assembling Machine 3",
				furnaceType: "Steel Furnace",
				miningDrillType: "Electric Mining Drill",
			};
		},
		methods: {
			updateProducerTypes(newValue, oldValue) {
				for (let materialWrapper of this.outputMaterials) {
					if (materialWrapper.producerType === oldValue) {
						materialWrapper.producerType = newValue;
					}
				}
			},
			craftSpeed(materialWrapper) {
				if (materialWrapper.producerType === "Assembling Machine 1") {
					return .5;
				} else if (materialWrapper.producerType === "Assembling Machine 2") {
					return .75;
				} else if (materialWrapper.producerType === "Assembling Machine 3") {
					return 1.25;
				} else if (materialWrapper.producerType === "Stone Furnace") {
					return .5;
				} else if (materialWrapper.producerType === "Burner Mining Drill") {
					return .5;
				} else {
					return 1;
				}
			},
			showFactoryPlan: function () {

				let factory = new Factory();

				if (this.assemblingMachineType === "Assembling Machine 1") {
					factory.ProducerProfile.AssemblingMachineType = AssemblingMachineType.AssemblingMachine1;
				} else if (this.assemblingMachineType === "Assembling Machine 2") {
					factory.ProducerProfile.AssemblingMachineType = AssemblingMachineType.AssemblingMachine2;
				} else if (this.assemblingMachineType === "Assembling Machine 3") {
					factory.ProducerProfile.AssemblingMachineType = AssemblingMachineType.AssemblingMachine3;
				}

				if (this.furnaceType === "Stone Furnace") {
					factory.ProducerProfile.FurnaceType = FurnaceType.Stone;
				} else if (this.furnaceType === "Steel Furnace") {
					factory.ProducerProfile.FurnaceType = FurnaceType.Steel;
				} else if (this.furnaceType === "Electric Furnace") {
					factory.ProducerProfile.FurnaceType = FurnaceType.Electric;
				}

				if (this.miningDrillType === "Burner Mining Drill") {
					factory.ProducerProfile.MiningDrillType = MiningDrillType.Burner;
				} else if (this.miningDrillType === "Electric Mining Drill") {
					factory.ProducerProfile.MiningDrillType = MiningDrillType.Electric;
				}

				factory.FinalAreaName = "Output Products";

				for (let materialWrapper of this.outputMaterials) {
					if (materialWrapper && materialWrapper.material && materialWrapper.numPerSecond > 0) {
						factory.AddOutput(materialWrapper.material, materialWrapper.numPerSecond * this.craftSpeed(materialWrapper));
					}
				}

				for (let materialWrapper of this.inputMaterials) {
					if (materialWrapper && materialWrapper.material) {
						factory.AddInput(materialWrapper.material);
					}
				}

				let tierNum = -1;
				for (let intermediateArea of this.intermediateAreas) {
					if (intermediateArea) {
						let atLeastOne = false;
						for (let materialWrapper of intermediateArea.materialWrappers) {
							if (materialWrapper && materialWrapper.material) {
								if (!atLeastOne) {
									atLeastOne = true;
									tierNum++;
								}
								factory.AddIntermediate(materialWrapper.material, tierNum);
							}
						}
						if (atLeastOne) {
							factory.SetTierName(tierNum, intermediateArea.name);
						}
					}
				}

				this.reportText = FactoryReporter.Report(factory);
			},
		},
		watch: {
			assemblingMachineType: {
				immediate: true,
				handler(newValue, oldValue) {
					this.updateProducerTypes(newValue, oldValue);
				},
			},
			furnaceType: {
				immediate: true,
				handler(newValue, oldValue) {
					this.updateProducerTypes(newValue, oldValue);
				},
			},
			miningDrillType: {
				immediate: true,
				handler(newValue, oldValue) {
					this.updateProducerTypes(newValue, oldValue);
				},
			},
		},
	}
</script>

<style lang="scss">
	/*This is necessary so that click events happen anywhere on the page, which ensures that any dropdowns listening for them are able to detect them so they can close themselves:*/
	html, body {
		height: 100%;
	}

    h2 {
        margin-top: 2rem;
    }

    h3 {
        margin-top: 1rem;
    }

    table {
        width: 100%;
    }

    .level0 {
        margin-left: 0;
    }

    .level1 {
        margin-left: 1rem;
    }

    .level2 {
        margin-left: 2rem;
    }

    .level3 {
        margin-left: 3rem;
    }

    .level4 {
        margin-left: 4rem;
    }

    .level5 {
        margin-left: 5rem;
    }

    .level6 {
        margin-left: 6rem;
    }

    .level7 {
        margin-left: 7rem;
    }

    .level8 {
        margin-left: 8rem;
    }

    .level9 {
        margin-left: 9rem;
    }

    .level10 {
        margin-left: 10rem;
    }

    th {
        font-weight: 500;
        font-style: italic;
    }
</style>


<style scoped>
	.section-description {
		color: #888888;
		font-style: italic;
		font-size: .8em;
		font-weight: 500;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 15px;
	}

	.inputsRow {
		display: flex;
		flex-direction: row;
	}

	.controlsDiv {
		flex-grow: 0;
	}

	.producer-types-div {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 6px;
	}

	select {
		padding: 1px 2px;
		border: 1px solid #888888;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 1px;
	}
</style>