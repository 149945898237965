<template>
	<div :class="valueWrapper.length == 1 ? (editOutputs ? 'materialListNoOutputsOneRow' : 'materialListOutputsOneRow') : (editOutputs ? 'materialListNoOutputs' : 'materialListOutputs')">
		<template v-for="(materialWrapper, index) in valueWrapper" :key="materialWrapper.id">
			<material-selector :materials="allMaterials" v-model="materialWrapper.material"></material-selector>
			<template v-if="editOutputs">
				<input type="number" class="per-second-input" v-model="materialWrapper.numPerSecond" />
				<span class="no-break">/sec at </span>
				<select v-model="materialWrapper.producerType" :disabled="!materialWrapper.material" class="producer-type-select">
					<template v-if="materialWrapper.material">
						<option>Exact</option>
					</template>
					<template v-else>
						<option>Select a Type</option>
					</template>
					<template v-if="isMinerType(materialWrapper)">
						<option>Burner Mining Drill</option>
						<option>Electric Mining Drill</option>
					</template>
					<template v-if="isPumpjackType(materialWrapper)">
						<option>Pumpjack</option>
					</template>
					<template v-if="isOffshorePumpType(materialWrapper)">
						<option>Offshore Pump</option>
					</template>
					<template v-if="isFurnaceType(materialWrapper)">
						<option>Stone Furnace</option>
						<option>Steel Furnace</option>
						<option>Electric Furnace</option>
					</template>
					<template v-if="isAssemblingMachineType(materialWrapper)">
						<option>Assembling Machine 1</option>
						<option>Assembling Machine 2</option>
						<option>Assembling Machine 3</option>
					</template>
					<template v-if="isOilRefineryType(materialWrapper)">
						<option>Oil Refinery</option>
					</template>
					<template v-if="isChemicalPlantType(materialWrapper)">
						<option>Chemical Plant</option>
					</template>
					<template v-if="isCentrifugeType(materialWrapper)">
						<option>Centrifuge</option>
					</template>
					<template v-if="isRocketSiloType(materialWrapper)">
						<option>Rocket Silo</option>
					</template>
					<template v-if="isNuclearReactorType(materialWrapper)">
						<option>Nuclear Reactor</option>
					</template>
				</select>
				<span class="no-break speed-span"> speed ({{craftSpeedString(materialWrapper)}})</span>
			</template>
			<button v-if="index !== valueWrapper.length - 1" @click="deleteMaterial(index)" class="delete-button"><i class="fa fa-trash"></i></button>
		</template>
	</div>
</template>

<script>
	import MaterialSelector from "./MaterialSelector.vue";
	import { ProducerType } from "@/logic/FactoryPlanner/Material";

	export default {
		name: 'MaterialListSelector',
		components: {
			'material-selector': MaterialSelector,
		},
		data: function () {
			return {
				nextId: 0,
			};
		},
		props: {
			modelValue: {
				type: Array,
				required: true,
			},
			allMaterials: {
				type: Array,
				required: true,
			},
			editOutputs: {
				type: Boolean,
				required: false,
			},
			defaultAssemblingMachineType: {
				type: String,
				required: false,
			},
			defaultFurnaceType: {
				type: String,
				required: false,
			},
			defaultMiningDrillType: {
				type: String,
				required: false,
			},
		},
		computed: {
			valueWrapper: {
				get: function () {
					return this.modelValue;
				},
				set: function (newValue) {
					this.$emit('update:modelValue', newValue);
				},
			},
		},
		methods: {
			deleteMaterial(index) {
				this.valueWrapper.splice(index, 1);
			},
			isMinerType(materialWrapper) {
				return materialWrapper.material && (materialWrapper.material.ProducerType === ProducerType.Miner);
			},
			isPumpjackType(materialWrapper) {
				return materialWrapper.material && (materialWrapper.material.ProducerType === ProducerType.Pumpjack);
			},
			isOffshorePumpType(materialWrapper) {
				return materialWrapper.material && (materialWrapper.material.ProducerType === ProducerType.OffshorePump);
			},
			isFurnaceType(materialWrapper) {
				return materialWrapper.material && (materialWrapper.material.ProducerType === ProducerType.Furnace);
			},
			isAssemblingMachineType(materialWrapper) {
				return materialWrapper.material && (materialWrapper.material.ProducerType === ProducerType.AssemblingMachine);
			},
			isOilRefineryType(materialWrapper) {
				return materialWrapper.material && (materialWrapper.material.ProducerType === ProducerType.OilRefinery);
			},
			isChemicalPlantType(materialWrapper) {
				return materialWrapper.material && (materialWrapper.material.ProducerType === ProducerType.ChemicalPlant);
			},
			isCentrifugeType(materialWrapper) {
				return materialWrapper.material && (materialWrapper.material.ProducerType === ProducerType.Centrifuge);
			},
			isRocketSiloType(materialWrapper) {
				return materialWrapper.material && (materialWrapper.material.ProducerType === ProducerType.RocketSilo);
			},
			isNuclearReactorType(materialWrapper) {
				return materialWrapper.material && (materialWrapper.material.ProducerType === ProducerType.NuclearReactor);
			},
			craftSpeedString(materialWrapper) {
				if (materialWrapper.producerType === "Assembling Machine 1") {
					return "50%";
				} else if (materialWrapper.producerType === "Assembling Machine 2") {
					return "75%";
				} else if (materialWrapper.producerType === "Assembling Machine 3") {
					return "125%";
				} else if (materialWrapper.producerType === "Stone Furnace") {
					return "50%";
				} else if (materialWrapper.producerType === "Burner Mining Drill") {
					return "50%";
				} else {
					return "100%";
				}
			},
		},
		watch: {
			valueWrapper: {
				immediate: true,
				deep: true,
				handler() {
					this.nextId++;

					// Temporary? This wasn't needed in the old copy of this code:
					if (!this.valueWrapper) {
						return;
					}

					if ((this.valueWrapper.length === 0) || (this.valueWrapper[this.valueWrapper.length - 1].material !== null)) {
						this.valueWrapper.push({
							id: this.nextId,
							material: null,
							numPerSecond: 1,
							producerType: "Select a Type",
						});
					}

					for (let materialWrapper of this.valueWrapper) {
						if (materialWrapper.material) {
							if ((materialWrapper.material.ProducerType === ProducerType.Miner)
								&& (materialWrapper.producerType !== "Exact")
								&& (materialWrapper.producerType !== "Burner Mining Drill")) {
								materialWrapper.producerType = this.defaultMiningDrillType ? this.defaultMiningDrillType : "Electric Mining Drill";
							} else if ((materialWrapper.material.ProducerType === ProducerType.Pumpjack)
								&& (materialWrapper.producerType !== "Exact")) {
								materialWrapper.producerType = "Pumpjack";
							} else if ((materialWrapper.material.ProducerType === ProducerType.OffshorePump)
								&& (materialWrapper.producerType !== "Exact")) {
								materialWrapper.producerType = "Offshore Pump";
							} else if ((materialWrapper.material.ProducerType === ProducerType.Furnace)
								&& (materialWrapper.producerType !== "Exact")
								&& (materialWrapper.producerType !== "Stone Furnace")
								&& (materialWrapper.producerType !== "Steel Furnace")
								&& (materialWrapper.producerType !== "Electric Furnace")) {
								materialWrapper.producerType = this.defaultFurnaceType ? this.defaultFurnaceType : "Steel Furnace";
							} else if ((materialWrapper.material.ProducerType === ProducerType.AssemblingMachine)
								&& (materialWrapper.producerType !== "Exact")
								&& (materialWrapper.producerType !== "Assembling Machine 1")
								&& (materialWrapper.producerType !== "Assembling Machine 2")
								&& (materialWrapper.producerType !== "Assembling Machine 3")) {
								materialWrapper.producerType = this.defaultAssemblingMachineType ? this.defaultAssemblingMachineType : "Assembling Machine 2";
							} else if ((materialWrapper.material.ProducerType === ProducerType.OilRefinery)
								&& (materialWrapper.producerType !== "Exact")) {
								materialWrapper.producerType = "Oil Refinery";
							} else if ((materialWrapper.material.ProducerType === ProducerType.ChemicalPlant)
								&& (materialWrapper.producerType !== "Exact")) {
								materialWrapper.producerType = "Chemical Plant";
							} else if ((materialWrapper.material.ProducerType === ProducerType.Centrifuge)
								&& (materialWrapper.producerType !== "Exact")) {
								materialWrapper.producerType = "Centrifuge";
							} else if ((materialWrapper.material.ProducerType === ProducerType.RocketSilo)
								&& (materialWrapper.producerType !== "Exact")) {
								materialWrapper.producerType = "Rocket Silo";
							} else if ((materialWrapper.material.ProducerType === ProducerType.NuclearReactor)
								&& (materialWrapper.producerType !== "Exact")) {
								materialWrapper.producerType = "Nuclear Reactor";
							}
						} else {
							materialWrapper.producerType = "Select a Type";
						}
					}
				},
			},
		},
	}
</script>

<style scoped>
	.materialListNoOutputs {
		display: grid;
		grid-template-columns: 2fr 0fr 0fr 1fr 0fr 0fr;
		column-gap: 6px;
		row-gap: 6px;
	}
	
	.materialListOutputs {
		display: grid;
		grid-template-columns: 1fr 0fr;
		column-gap: 6px;
		row-gap: 6px;
	}
	.materialListNoOutputsOneRow {
		display: grid;
		grid-template-columns: 2fr 0fr 0fr 1fr 0fr;
		column-gap: 6px;
		row-gap: 6px;
	}
	
	.materialListOutputsOneRow {
		display: grid;
		grid-template-columns: 1fr;
		column-gap: 6px;
		row-gap: 6px;
	}

	.delete-button {
		border: 1px solid #888888;
		border-radius: 4px;
	}

	.no-break {
		white-space: nowrap;
	}

	.per-second-input {
		width: 40px;
		border: 1px solid #888888;
		-moz-appearance: textfield;
		text-align: center;
	}

	.per-second-input::-webkit-outer-spin-button,
	.per-second-input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.producer-type-select {
		padding: 1px 2px;
		border: 1px solid #888888;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 1px;
	}
</style>