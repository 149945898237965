import { Material } from "./Material";
import { MaterialQuantity } from "./MaterialQuantity";
import { Utils } from "./Utils";

export class AssemblyArea {
	private outputs: Record<string, MaterialQuantity> = {};
	private shippedThrough: Record<string, MaterialQuantity> = {};
	private inputs: Material[] = [];

	public Name: string;

	// Kinda hacky ... only exists on the first area, and is generally sort of lame in how it's created ... but I guess it works
	public TotalInputs: MaterialQuantity[] = [];

	public get HasOutputs(): boolean {
		return this.Outputs.length > 0;
	}

	public get Outputs(): MaterialQuantity[] {
		return Utils.ConvertToArray(this.outputs, false);
	}

	public get ShippedThrough(): MaterialQuantity[] {
		return Utils.ConvertToArray(this.shippedThrough, false);
	}

	public constructor(name: string) {
		this.Name = name;
	}

	public AddOutput(material: Material, numPerSec: number): void {
		this.outputs[material.Name] = new MaterialQuantity(material, numPerSec);
	}

	public AddInput(material: Material): void {
		this.inputs.push(material);
	}

	public AddShippedThrough(material: Material, numPerSec: number): void {
		this.shippedThrough[material.Name] = new MaterialQuantity(material, numPerSec);
	}

	public IsInput(material: Material): boolean {
		return this.inputs.indexOf(material) !== -1;
	}

	public GetShippedIn(): MaterialQuantity[] {
		const shippedIn: Record<string, MaterialQuantity> = {};
		for (const materialName in this.outputs) {
			this.MergeInputs(shippedIn, this.outputs[materialName].GetInputQuantities(this.inputs));
		}
		return Utils.ConvertToArray(shippedIn, true);
	}

	private MergeInputs(shippedIn: Record<string, MaterialQuantity>, forOneOutput: MaterialQuantity[]): void {
		for (const quantity of forOneOutput) {
			if (shippedIn[quantity.Material.Name]) {
				shippedIn[quantity.Material.Name].AddNumPerSec(quantity.NumPerSec);
			}
			else {
				shippedIn[quantity.Material.Name] = new MaterialQuantity(quantity.Material, quantity.NumPerSec);
			}
		}
	}
}