import { AssemblyArea } from "./AssemblyArea";
import { Material } from "./Material";
import { MaterialQuantity } from "./MaterialQuantity";
import { AssemblingMachineType, FurnaceType, MiningDrillType, ProducerProfile } from "./ProducerProfile";
import { Utils } from "./Utils";

export class Factory {
	private outputs: MaterialQuantity[] = [];
	private inputs: Material[] = [];
	private intermediates: Material[][] = [];
	private bannedInputs: Material[][] = [];
	private intermediateNames: string[] = [];

	public FinalAreaName: string;
	public readonly ProducerProfile: ProducerProfile;

	public constructor() {
		this.ProducerProfile = new ProducerProfile();
		this.ProducerProfile.AssemblingMachineType = AssemblingMachineType.AssemblingMachine2;
		this.ProducerProfile.FurnaceType = FurnaceType.Electric;
		this.ProducerProfile.MiningDrillType = MiningDrillType.Electric;
		this.FinalAreaName = "";
	}

	public AddOutput(material: Material, numPerSec: number): void {
		this.outputs.push(new MaterialQuantity(material, numPerSec));
	}

	public AddInput(material: Material) {
		this.inputs.push(material);
	}

	public AddIntermediate(material: Material, tier: number): void {
		while (!(this.intermediates.length > tier)) {
			this.intermediates.push([]);
			this.bannedInputs.push([]);
			this.intermediateNames.push("");
		}
		this.intermediates[tier].push(material);
	}

	public BanInput(material: Material, tier: number): void {
		while (!(this.bannedInputs.length > tier)) {
			this.intermediates.push([]);
			this.bannedInputs.push([]);
			this.intermediateNames.push("");
		}
		this.bannedInputs[tier].push(material);
	}

	public SetTierName(tier: number, name: string): void {
		while (!(this.intermediateNames.length > tier)) {
			this.intermediates.push([]);
			this.bannedInputs.push([]);
			this.intermediateNames.push("");
		}
		this.intermediateNames[tier] = name;
	}

	public ComputeAssemblyAreas(): AssemblyArea[] {
		const assemblyAreas: AssemblyArea[] = [];

		// Final output first:
		const area = new AssemblyArea(this.FinalAreaName);
		for (const quantity of this.outputs) {
			area.AddOutput(quantity.Material, quantity.NumPerSec);
		}
		for (const material of this.inputs) {
			area.AddInput(material);
		}
		for (const intermediateList of this.intermediates) {
			for (const material of intermediateList) {
				area.AddInput(material);
			}
		}
		assemblyAreas.push(area);

		// Now all the intermediates (in reverse order, because you don't know the quantity output of one until you figure out the input need of the next):
		for (let i = this.intermediates.length - 1; i >= 0; i--) {
			// Figure out the output needed:
			const intermediateList = this.intermediates[i];

			const neededOutput: Record<string, MaterialQuantity> = {};
			for (const material of intermediateList) {
				neededOutput[material.Name] = new MaterialQuantity(material, 0);
			}

			const shippedThrough = this.GetShippedThrough(assemblyAreas, neededOutput);

			// Create the assembly area:
			const area = new AssemblyArea(this.intermediateNames[i]);
			for (const materialName in neededOutput) {
				const quantity = neededOutput[materialName];
				area.AddOutput(quantity.Material, quantity.NumPerSec);
			}
			for (const materialName in shippedThrough) {
				const quantity = shippedThrough[materialName];
				area.AddShippedThrough(quantity.Material, quantity.NumPerSec);
			}
			// Check for inputs from inputs:
			for (const material of this.inputs) {
				if (this.bannedInputs[i].indexOf(material) === -1) {
					area.AddInput(material);
				}
			}

			// Check for inputs from prior intermediates:
			for (let j = 0; j < i; j++) {
				for (const material of this.intermediates[j]) {
					if (this.bannedInputs[i].indexOf(material) === -1) {
						area.AddInput(material);
					}
				}
			}
			assemblyAreas.push(area);
		}

		// Flip the order, since they were created later to earlier:
		const returnVal: AssemblyArea[] = [];
		for (let i = assemblyAreas.length - 1; i >= 0; i--) {
			if (assemblyAreas[i].HasOutputs) {
				returnVal.push(assemblyAreas[i]);
			}
		}

		// Now get the inputs for the whole thing:
		if (returnVal.length > 0) {
			returnVal[0].TotalInputs = Utils.ConvertToArray(this.GetShippedThrough(assemblyAreas, {}), false);
		}

		return returnVal;
	}

	// This acts as both a "shipped through" and "inputs" thing ... if neededOutput is used, it's doing shipped through
	private GetShippedThrough(assemblyAreas: AssemblyArea[], neededOutput: Record<string, MaterialQuantity>): Record<string, MaterialQuantity> {
		const createdLater: Material[] = [];
		for (const area of assemblyAreas) {
			for (const quantity of area.Outputs) {
				createdLater.push(quantity.Material);
			}
		}

		const inputs: Record<string, MaterialQuantity> = {};

		for (const area of assemblyAreas) {
			const shippedIn = area.GetShippedIn();
			for (const quantity of shippedIn) {
				if (neededOutput[quantity.Material.Name]) {
					neededOutput[quantity.Material.Name].AddNumPerSec(quantity.NumPerSec);
				}
				else if (createdLater.indexOf(quantity.Material) === -1) {
					if (!inputs[quantity.Material.Name]) {
						inputs[quantity.Material.Name] = new MaterialQuantity(quantity.Material, quantity.NumPerSec);
					}
					else {
						inputs[quantity.Material.Name].AddNumPerSec(quantity.NumPerSec);
					}
				}
			}
		}

		return inputs;
	}
}