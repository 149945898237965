<template>
	<div>
		<div class="assembly-areas">
			<template v-for="(assemblyArea, index) in valueWrapper" :key="index">
				<div class="area-title-div">
					<input type="text" v-model="assemblyArea.name" class="nameInput" />
					<button @click="deleteArea(index)" class="delete-area-button"><i class="fa fa-trash"></i></button>
				</div>
				<material-list-selector v-model="assemblyArea.materialWrappers" :allMaterials="allMaterials" class="materials"></material-list-selector>
			</template>
		</div>
		<button @click="addArea" class="add-area-button"><i class="fa fa-plus"></i> Add New Area</button>
	</div>
</template>

<script>
	import MaterialListSelector from "./MaterialListSelector.vue";

	export default {
		name: 'FactoryPlanner',
		components: {
			'material-list-selector': MaterialListSelector,
		},
		props: {
			modelValue: {
				type: Array,
				required: true,
			},
			allMaterials: {
				type: Array,
				required: true,
			},
		},
		computed: {
			valueWrapper: {
				get: function () {
					return this.modelValue;
				},
				set: function (newValue) {
					this.$emit('update:modelValue', newValue);
				},
			},
		},
		methods: {
			addArea() {
				let areaNum = this.valueWrapper.length + 1;
				this.valueWrapper.push({
					name: "Area " + areaNum,
					materialWrappers: [],
				});
			},
			deleteArea(index) {
				this.valueWrapper.splice(index, 1);
			},
		},
	}
</script>

<style scoped>
	.assembly-areas {
		display: flex;
		flex-direction: column;
	}

	.nameInput {
		margin-right: 6px;
		flex-grow: 1;
	}

	.materials {
		margin: 0 0 0 15px;
	}

	.add-area-button {
		border: 1px solid #888888;
		border-radius: 4px;
		margin-top: 6px;
	}

	.delete-area-button {
		border: 1px solid #888888;
		border-radius: 4px;
	}

	.area-title-div {
		display: flex;
		flex-direction: row;
		margin: 6px 0;
	}
</style>