<template>
    <h1>Stardew Valley: First Six Days (No Pants)</h1>
    <span class="h1-description">Note: Not wearing pants is technically optional, but highly recommended.</span>

    <h2>Purpose of this Guide</h2>
    <p>
        Ah, Stardew Valley! A peaceful, quaint little town where one can slow down and take part in all of the
        simple pleasures of life: making friends, breathing wholesome country air, working with your own hands, and maybe
        finding true love and settling down. It's the perfect escape from the hustle and bustle of city life.
    </p>
    <p>
        Unless, of course, you are me, in which case you find yourself ruthlessly squeezing every last drop of
        productivity out of each day!
    </p>
    <p>
        You don't have to play this game like I do, of course, but you can still benefit from getting a good
        start. In the beginning of the game you have a very limited amount of money and energy, and it can be
        a bit overwhelming trying to figure out how best to use them. This guide will show you how. And we'll
        do it all with no pants on!
    </p>
    <div class="image-div">
        <img src="@/assets/images/stardewvalley/rob.png" class="img-fluid" loading="lazy" />
        <span class="image-description">Meet Rob! He's living free from the restrictive shackles known as pants. Hi, Rob!</span>
    </div>

    <h2>General Strategy</h2>
    <p>
        I break down what to do each day from Day 1 through Day 6 below, but first let's talk general strategy.
        You have two big bottlenecks early: money and energy. Both are very limited.
    </p>
    <h3>Money</h3>
    <p>
        In the very early game, fishing is by far the most profitable thing you can do. After the first few days
        you can easily bring in $5000 a day or more from fishing. But in the long run, crops will make you much
        more than that, and with much less effort (especially once you have <a href="https://stardewvalleywiki.com/Sprinkler">sprinklers</a>), so you'll need to switch
        eventually.
    </p>
    <p>
        A good thing to plan around is Day 13, which is the <a href="https://stardewvalleywiki.com/Egg_Festival">egg festival</a> where you can buy
        <a href="https://stardewvalleywiki.com/Strawberry_Seeds">strawberry seeds</a>.
        <a href="https://stardewvalleywiki.com/Strawberry">Strawberries</a> are the most profitable spring crop, even when you don't plant them until this late in the
        season, so if you can afford to buy a bunch of them and you are able to water them, you'll have quite
        a lot of money to work with when summer starts.
    </p>
    <h3>Energy</h3>
    <p>
        Don't rely only on the energy you start with get to you through the day. You'll need plenty of food to maximize your
        output. In the long run you can afford to buy food (I recommend <a href="https://stardewvalleywiki.com/Salad">salads</a>,
        which you can buy from <a href="https://stardewvalleywiki.com/The_Stardrop_Saloon">The Stardrop Saloon</a>). But in the
        early game you just can't afford this and you'll need to farm, fish, or find your foods.
    </p>
    <p>
        See the Early Food Guide table at the bottom of this page for tips on which items are best used as food.
    </p>
    <h3>Other Tips</h3>
    <ul>
        <li>
            Other than a few <a href="https://stardewvalleywiki.com/Parsnip">parsnips</a> on Day 1, don't bother planting until at least Day 6. Spend your time fishing instead.
        </li>
        <li>
            On Day 6, you can plant <a href="https://stardewvalleywiki.com/Potato_Seed">potato seeds</a> to harvest on Day 12 so you can buy <a href="https://stardewvalleywiki.com/Strawberry_Seeds">strawberry seeds</a>
            on Day 13. But you'll probably make more money if you skip the <a href="https://stardewvalleywiki.com/Potato">potatoes</a> and just keep fishing until Day 13.
        </li>
        <li>
            Before leaving your farm, always place everything you don't need in a <a href="https://stardewvalleywiki.com/Chest">chest</a> to save inventory space.
        </li>
        <li>
            Try to focus days on a single activity. Spend a full day fishing rather than two half days and you'll only walk to the water once.
        </li>
        <li>
            Wait to process geodes until you have a whole bunch of them. Walking all the way to <a href="https://stardewvalleywiki.com/Blacksmith">Clint's</a> is time-consuming.
        </li>
        <li>
            At first, only go mining if you can focus a full day on it. Try to go down at least 20 to 25 levels.
        </li>
        <li>
            Don't go mining with an under-powered pickaxe. Upgrade your pickaxe first when you can.
        </li>
        <li>
            Stay up late. If you are in bed by 1:50, you'll be fine. Early in the game there is no penalty
            for this. Later in the game you'll have reduced energy the next day if you stay up past 12, but it's still worth it.
        </li>
        <li>
            Bundles and people can wait. You should be able to take care of all of your spring bundles in the second half of spring. In the first
            half, you just don't have the time and energy. Likewise, you can mostly ignore the townspeople for the first half of the spring
            and start building relationships once you are finished with that initial phase.
        </li>
        <li>
            Prioritize getting far down into the mines quickly, because you'll need <a href="https://stardewvalleywiki.com/Iron_Bar">iron</a>,
            <a href="https://stardewvalleywiki.com/Refined_Quartz">quartz</a>, and <a href="https://stardewvalleywiki.com/Gold_Bar">gold</a> to make
            <a href="https://stardewvalleywiki.com/Sprinkler">sprinklers</a>, which you need if you want to farm lots of crops.
        </li>
    </ul>
    <p>
        But most importantly, whatever you do, don't wear pants! Pants are restrictive, and not very stylish if you ask me. Who needs 'em?
    </p>

    <h2>Day 1</h2>
    <p>
        Welcome to Stardew Valley!
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/hello.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/hello.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/hello.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Robin, meet Rob!</span>
    </div>
    <p>
        First things first, make sure to do the following:
    </p>
    <p>
        1. Go into settings and set your zoom settings all the way to 75%. Now you can see things farther away.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/zoom.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/zoom.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/zoom.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">If you never knew about this setting, I probably just made your day</span>
    </div>
    <p>
        2. Take off your pants.
    </p>
    <div class="image-div">
        <img src="@/assets/images/stardewvalley/nopants.gif" class="img-fluid" loading="lazy" />
        <span class="image-description">I definitely won't be needing these</span>
    </div>
    <p>
        Now that you are out of your house with your leg hairs comfortably flapping in the wind, there are a few things you should do on the farm before you leave.
        First, cut down enough trees to get 50 wood, so you can craft a <a href="https://stardewvalleywiki.com/Chest">chest</a>. Usually, 3 trees is enough. I'd suggest
        placing the <a href="https://stardewvalleywiki.com/Chest">chest</a> right next to your drop box.
    </p>
    <p>
        Next, you'll want to clear a little space in front of your house for your 15 <a href="https://stardewvalleywiki.com/Parsnip_Seeds">parsnip seeds</a> (or, if you
        want, go ahead and make room for 18 <a href="https://stardewvalleywiki.com/Parsnip_Seeds">parsnip seeds</a>, because you'll be buying 3 more). Plant and water
        the seeds.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/initialcrops.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/initialcrops.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/initialcrops.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Pretty Planted <a href="https://stardewvalleywiki.com/Parsnip">Parsnips</a></span>
    </div>
    <p>
        Finally, clear a path to the northern entrance to your farm and another path to the southern entrance of your farm. Otherwise, on a later day, you may enter your farm
        from the north or south and find that you are blocked and can't get to your house.
    </p>
    <p>
        At this point, you are probably already low on energy. But don't worry, this is the one day where you won't need any more. Drop everything but your scythe in
        the <a href="https://stardewvalleywiki.com/Chest">chest</a> and head out!
    </p>
    <p>
        You can't do much today so it is a good day to find foragables (especially the <a href="https://stardewvalleywiki.com/Spring_Onion">spring onions</a> to the south) and meet the townspeople.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/springonions.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/springonions.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/springonions.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">The <a href="https://stardewvalleywiki.com/Spring_Onion">spring onions</a> are a great thing to grab on the first day, but after today they usually aren't worth it</span>
    </div>
    <p>
        While you are out, make sure to buy 3 more <a href="https://stardewvalleywiki.com/Parsnip_Seeds">parsnip seeds</a> from <a href="https://stardewvalleywiki.com/Pierre%27s_General_Store">Pierre's store</a>.
        These are just to make absolutely sure that when you harvest your <a href="https://stardewvalleywiki.com/Parsnip">parsnips</a> you'll reach at least level 1 farming (even if crows eat a few).
    </p>
    <p>
        At the end of the day, make sure to plant your last 3 <a href="https://stardewvalleywiki.com/Parsnip_Seeds">parsnip seeds</a>. If you have extra time, you can go around
        on your farm with your scythe and harvest weeds (this doesn't cost energy). You'll occassionally get <a href="https://stardewvalleywiki.com/Mixed_Seeds">mixed seeds</a>
        from this, which you can plant if you want (and if you don't
        plant them that's probably fine, too).
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/dark.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/dark.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/dark.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Watering crops after midnight. What could go wrong?</span>
    </div>
    <p>
        Before you go to bed, put anything worth selling in the drop box. Do not sell <a href="https://stardewvalleywiki.com/Spring_Onion">spring onions</a>,
        <a href="https://stardewvalleywiki.com/Dandelion">dandelions</a>, or <a href="https://stardewvalleywiki.com/Leek">leeks</a>; you will want those for food later,
        so put them in your <a href="https://stardewvalleywiki.com/Chest">chest</a> instead.
    </p>

    <h2>Day 2</h2>
    <p>
        Today is your first fishing day! You'll get a letter from <a href="https://stardewvalleywiki.com/Willy">Willy</a> saying ... oh, who am I kidding, I never read these things.
        But now you can go pick up a fishing rod from him!
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/willy.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/willy.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/willy.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">A stranger who owns a bait shop has "something" for me. Yeah, that's not creepy at all.</span>
    </div>
    <p>
        Before you leave, make sure to water your crops and put all of your stuff into your box, including the food you collected yesterday. You won't have much inventory space today. If
        you need energy, you can eat forageables you find and fish you catch.
    </p>
    <p>
        Go to the beach and get your fishing pole from <a href="https://stardewvalleywiki.com/Willy">Willy</a>.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/fishingrod.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/fishingrod.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/fishingrod.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">It turns out <a href="https://stardewvalleywiki.com/Willy">Willy</a> just wanted to give me a free fishing rod. Whew!</span>
    </div>
    <p>
        Head up north, to the mountain lake, to the spot one tile above the eastern edge of the fence to the south (see the picture below). This is the best place to fish on
        non-rainy days in spring. Cast directly to the east, but hold the "w" key as you cast so your lure moves north a little, which will get you into slightly deeper water.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/fishingspot1.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/fishingspot1.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/fishingspot1.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">This is the ideal fishing spot in spring on non-rainy days</span>
    </div>
    <p>
        Spend your entire day fishing. Try your best to get as many "perfects" as possible because they increase your xp a ton and get you better
        quality fish. If you run out of energy, you can eat some of the fish you catch for more.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/sebastian.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/sebastian.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/sebastian.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Oh, hi, <a href="https://stardewvalleywiki.com/Sebastian">Sebastian</a>! You like this spot, too, eh?</span>
    </div>
    <p>
        Inventory management is going to be very difficult today because you probably won't have enough room for everything you catch. Just try your best to keep the most valuable things.
        If you have to get rid of something, and it's edible, eat it rather than simply discarding it, so you can get a bit of energy from it.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/decisions.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/decisions.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/decisions.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Decisions, decisions ... I guess I'll just get rid of that soggy piece of paper</span>
    </div>
    <p>
        You can safely wait until at least midnight to leave. 
    </p>
    <p>
        You may not reach level 3 fishing today, but hopefully you'll be pretty close to it. Some people think you should wait until fishing level 5 to sell your fish, because then
        you can sell them for more money, but I do not recommend this because you are going to need that money for a <a href="https://stardewvalleywiki.com/Fiberglass_Rod">fiberglass fishing rod</a>
        and <a href="https://stardewvalleywiki.com/Bait_(item)">bait</a> on Day 3 so you can catch many
        more fish. So, if you have any iridium-quality <a href="https://stardewvalleywiki.com/Chub">chubs</a>, go ahead and keep them for food, but sell all the other fish you catch
        by putting them in the dropbox.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/day2end.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/day2end.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/day2end.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">This is less than I've gotten on other attempts at Day 2 but it should still be enough</span>
    </div>
    <p>
        When Day 3 starts, you'll want to have at least $2,300 so you can buy what you need.
    </p>

    <h2>Day 3</h2>
    <p>
        Ah, another day of fishing! Fair warning: this can be a difficult day. In addition to inventory troubles, you'll also be trying to get all the way to fishing level 5, and
        you'll be trying to catch <a href="https://stardewvalleywiki.com/Catfish">catfish</a>,
        which are very difficult to catch but sell for a lot of money. You may need to restart today if things go too badly.
    </p>
    <p>
        But <i>you can do it!</i>
    </p>
    <p>
        Today is a guaranteed rainy day, which means you don't need to water your crops. But, unfortunately, the rain won't stop those vile crows!
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/crow.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/crow.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/crow.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">One need only look into the blood-red eyes of the crow to know that it is truly evil</span>
    </div>
    <p>
        Since it is raining, you can make more money fishing in the river than the lake. Your highest priority is
        getting to <a href="https://stardewvalleywiki.com/Fish_Shop">Willy's</a> as soon as it opens so you can buy the <a href="https://stardewvalleywiki.com/Fiberglass_Rod">fiberglass fishing rod</a>
        and some <a href="https://stardewvalleywiki.com/Bait_(item)">bait</a>. From this time on, you should
        <i>never</i> fish without <a href="https://stardewvalleywiki.com/Bait_(item)">bait</a> because you won't catch nearly as many fish without it.
        But you can't go straight to <a href="https://stardewvalleywiki.com/Fish_Shop">Willy's</a> because it won't be open yet, so you'll have to
        spend a little time fishing first.
    </p>
    <p>
        Drop off all your inventory in your <a href="https://stardewvalleywiki.com/Chest">chest</a> except for your fishing rod, and then head to your fishing spot for today,
        which is on the south-west side of the town. See the image
        below for guidance. Cast your lure directly south, but hold the "a" key on your keyboard to move the lure a little to the west so it goes into slightly deeper water.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/fishingspot2.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/fishingspot2.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/fishingspot2.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">This is the best place to fish on rainy days in spring</span>
    </div>
    <p>
        Fish until about 8:30 am, then head to <a href="https://stardewvalleywiki.com/Fish_Shop">Willy's shop</a> and buy the <a href="https://stardewvalleywiki.com/Fiberglass_Rod">fiberglass fishing rod</a>
        and at least 100 pieces of
        <a href="https://stardewvalleywiki.com/Bait_(item)">bait</a> (200 is probably a better idea or even 300 if you can afford it).
        While you are at <a href="https://stardewvalleywiki.com/Fish_Shop">Willy's</a>, you should probably sell the few fish you caught this morning. They'll be worth more this evening
        after you've reached fishing level 5,
        but inventory is going to be at a premium so you'll need all the space you can get.
    </p>
    <p>
        Head back to your fishing spot by the river and fish there all day. You can safely wait until about midnight to go home.
    </p>
    <p>
        Inventory management is going to be just as bad or worse than Day 2. But don't worry, starting tomorrow that will get easier. For now, you have some tough decisions to make
        about what to keep and what to sell!
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/rodtoss.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/rodtoss.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/rodtoss.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Do you think <a href="https://stardewvalleywiki.com/Willy">Willy</a> will be mad if he finds out that I threw away the special fishing rod he gave me yesterday?</span>
    </div>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/ancientseed.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/ancientseed.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/ancientseed.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">I was <i>seriously</i> considering throwing away that ancient seed.</span>
    </div>
    <p>
        Make sure you reach level 5 fishing today. I didn't reach level 4 until 4 p.m., but I was still able to reach level 5 just before the end of the day at 11:40. On other attempts, I have
        managed to get to level 4 as early as 1:20 and level 5 at 7:40. So you have a good chance of getting to level 5, but if you don't, you should probably restart this day and try again.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/level5.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/level5.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/level5.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Just barely made it to level 5 in time. Whew!</span>
    </div>
    <p>
        If you have any iridium-quality <a href="https://stardewvalleywiki.com/Smallmouth_Bass">smallmouth bass</a>, keep them for food. Sell everything else by putting it into the dropbox
        before bed. When given the choice between the "Fisher" profession and the "Trapper" profession, choose "Fisher". (A side note about professions: if you need help figuring out which
        other professions to pick, check out <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=640103954">this handy guide</a>.)
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/fisher.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/fisher.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/fisher.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Cha-ching!</span>
    </div>

    <h2>Day 4</h2>
    <p>
        After two full days of fishing, you're probably ready for something else, huh? Well, too bad! You're going fishing again! But don't forget to check your mail first. You have
        an important message from <a href="https://stardewvalleywiki.com/Willy">Willy</a> about ... hey, hold up, did he send me a letter telling me I could buy the stuff I already bought yesterday?
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/willmail.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/willmail.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/willmail.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Maybe <a href="https://stardewvalleywiki.com/Willy">Willy</a> is developing memory troubles in his old age?</span>
    </div>
    <p>
        Don't forget to water your crops before you head out.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/crowhole.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/crowhole.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/crowhole.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">That crow left a hole in my crops ... and in my heart</span>
    </div>
    <p>
        Inventory won't a problem today, but you should still put everything but your fishing pole into your <a href="https://stardewvalleywiki.com/Chest">chest</a>,
        except maybe for some food items
        (<a href="https://stardewvalleywiki.com/Spring_Onion">spring onions</a>, <a href="https://stardewvalleywiki.com/Dandelion">dandelions</a>, and
        <a href="https://stardewvalleywiki.com/Leek">leeks</a> are good choices to bring).
    </p>
    <p>
        Now, head the <a href="https://stardewvalleywiki.com/Pierre%27s_General_Store">Pierre's</a> to buy the backpack upgrade! This will pretty much solve your inventory troubles,
        at least until you get past level 80 in the mines. It's
        $2,000, but it's totally worth it. He probably won't be open as early as you can get there, so can spend a few minutes either fishing or hunting for forageables
        before getting to his shop right at 9 am.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/backpack.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/backpack.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/backpack.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">No shirt, No shoes, No service. But nobody said anything about pants.</span>
    </div>
    <p>
        Fish at the lake all day. If you need to eat, consume <a href="https://stardewvalleywiki.com/Green_Algae">green algae</a> first, then your forageables
        (<a href="https://stardewvalleywiki.com/Spring_Onion">spring onions</a>,
        <a href="https://stardewvalleywiki.com/Dandelion">dandelions</a>, and
        <a href="https://stardewvalleywiki.com/Leek">leeks</a>) next, and finally
        iridium-quality <a href="https://stardewvalleywiki.com/Chub">chubs</a>.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/dejavu.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/dejavu.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/dejavu.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description"><a href="https://stardewvalleywiki.com/Sebastian">Sebastian</a> again? Deja vu!</span>
    </div>
    <p>
        Head home around midnight or a little later and sell all of your fish except the
        iridium-quality <a href="https://stardewvalleywiki.com/Chub">chubs</a>, which you should again keep for food.
    </p>

    <h2>Day 5</h2>
    <p>
        First things first: you got a cute kitty!
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/kitty.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/kitty.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/kitty.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Don't you just want to squish his little face!</span>
    </div>
    <p>
        Also, check your mail. MOM SENT <a href="https://stardewvalleywiki.com/Cookie">COOKIES</a>!
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/cookies.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/cookies.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/cookies.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description"><a href="https://stardewvalleywiki.com/Cookie">COOKIES</a>!</span>
    </div>
    <p>
        Once you've gotten over the euphoria of getting both <a href="https://stardewvalleywiki.com/Cookie">cookies</a> and a kitty <i>in the same day</i>, go ahead and harvest your
        <a href="https://stardewvalleywiki.com/Parsnip">parsnips</a> and water the handful
        of remainings crops you have from <a href="https://stardewvalleywiki.com/Mixed_Seeds">mixed seeds</a> (if you have any at all). You won't be planting anything new today.
        Don't sell your <a href="https://stardewvalleywiki.com/Parsnip">parsnips</a>! They will be very useful food.
    </p>
    <p>
        Drop everything in your inventory into your <a href="https://stardewvalleywiki.com/Chest">chest</a> except for your pickaxe and some food (you will <i>definitely</i> need food today).
        And then head for the mines!
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/check.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/check.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/check.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description"><a href="https://stardewvalleywiki.com/Cookie">Cookies</a>? Check! <a href="https://stardewvalleywiki.com/Parsnip">Parsnips</a>? Check! Pants? NEVER!</span>
    </div>
    <p>
        When you mine, your main goal should be to find the ladder to go down to the next level, and your secondary goal should be to get copper. Skip monsters altogether if and when
        you can. If you find a ladder, immediately go down it, unless you see copper very close by in which case pick that up first and then go down.
    </p>
    <p>
        You really want to get down to at least level 20 in the mines today, if not 25 or even 30 if you are really lucky. You will also want to get plenty of copper. That's a tall order,
        and may require a restart, but this is one of the days where it is worth it to restart the day if you need to.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/level30.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/level30.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/level30.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">This was a very lucky day! I made it all the way to level 30 and I mined over 90 copper ore!</span>
    </div>
    <p>
        Go ahead and sell any gems you found, but definitely hold on to ore and coal. You won't make much money today, but every little bit helps.
    </p>

    <h2>Day 6</h2>
    <p>
        <a href="https://stardewvalleywiki.com/Clint">Clint</a> gives you the plans to craft a <a href="https://stardewvalleywiki.com/Furnace">furnace</a> today, and just in time, too! You'll be using it immediately.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/furnace.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/furnace.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/furnace.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Thanks, <a href="https://stardewvalleywiki.com/Clint">Clint</a>!</span>
    </div>
    <p>
        Create a <a href="https://stardewvalleywiki.com/Furnace">furnace</a> right away and start smelting your
        <a href="https://stardewvalleywiki.com/Copper_Ore">copper ores</a> into <a href="https://stardewvalleywiki.com/Copper_Bar">copper bars</a>.
        You'll need 5 <a href="https://stardewvalleywiki.com/Copper_Bar">copper bars</a> as soon as possible, because you are going to be upgrading your pickaxe today.
        Go ahead and water your crops if you have any while you get your <a href="https://stardewvalleywiki.com/Copper_Bar">copper bars</a> ready.
        You should also craft a <a href="https://stardewvalleywiki.com/Scarecrow">scarecrow</a> and place it next to your remaining crops.
        Once your <a href="https://stardewvalleywiki.com/Copper_Bar">copper bars</a> are ready, head to
        <a href="https://stardewvalleywiki.com/Blacksmith">Clint's</a> and upgrade your pickaxe.
    </p>
    <p>
        On the way, you'll see the cutscene for the community center. You should be sure to go inside the community center and click on the little gliph thingy (that's it's
        official name, I think). Otherwise you'll have to come back and do that later, because the plot won't continue forward until you do it.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/glyphthingy.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/glyphthingy.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/glyphthingy.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">Right, okay, that's exactly what I was thinking</span>
    </div>
    <p>
        Now, you have a choice to make. You can either buy a whole bunch of <a href="https://stardewvalleywiki.com/Potato_Seeds">potato seeds</a> or continue fishing.
    </p>
    <p>
        If you buy <a href="https://stardewvalleywiki.com/Potato_Seeds">potato seeds</a> and plant and water them immediately, they'll be ready to harvest on Day 12, just in time to sell
        them so you'll have money to buy <a href="https://stardewvalleywiki.com/Strawberry_Seeds">strawberry seeds</a> on Day 13. But this means you'll have to water a bunch of crops every
        day and you won't have as much time for fishing (which is more profitable) or for going to the mines.
    </p>
    <p>
        If you decide to fish instead, you'll be fishing most days until Day 13. I think this is the best thing in the long run, so unless you can't stand the idea of spending
        another week fishing, I suggest taking this route.
    </p>
    <p>
        If you decide to buy <a href="https://stardewvalleywiki.com/Potato_Seeds">potato seeds</a>, you're on your own from this point on, because the rest of my suggestions assume you went fishing.
    </p>
    <p>
        But if you decide to go fishing, well ... you know what comes next.
    </p>
    <div class="image-div">
        <picture>
            <source srcset="@/assets/images/stardewvalley/nosebastian.webp" type="image/webp">
            <source srcset="@/assets/images/stardewvalley/nosebastian.jpg" type="image/jpeg">
            <img src="@/assets/images/stardewvalley/nosebastian.jpg" class="img-fluid" loading="lazy">
        </picture>
        <span class="image-description">I'm so lonely ... not even <a href="https://stardewvalleywiki.com/Sebastian">Sebastian</a> wants to hang out with me anymore. Why must the world be so prejudice against the pantless?</span>
    </div>

    <h2>Day 7 and Beyond</h2>
    <p>
        For the next few days, I suggest doing something like the following:
    </p>
    <ul>
        <li>
            Day 7: Fish
        </li>
        <li>
            Day 8: Get your copper pickaxe from <a href="https://stardewvalleywiki.com/Blacksmith">Clint</a>, give him your watering can to upgrade, and then spend the day in the mines.
            Try to get at least 20-25 levels deeper and, most importantly, have at least 25 iron ore (you may have already gotten some from fishing or geodes, so this may not be too difficult).
            If daily luck is really bad today, you can fish today and go to the mines tomorrow, instead.
        </li>
        <li>
            Day 9: Fish
        </li>
        <li>
            Day 10: Start getting your watering can upgraded to steel, then fish.
        </li>
        <li>
            Day 11: Fish
        </li>
        <li>
            Day 12: Get steel watering can, start on copper axe, go fishing
        </li>
        <li>
            Day 13: Buy a bunch of <a href="https://stardewvalleywiki.com/Strawberry_Seeds">strawberry seeds</a> and plant as many as possible (you can safely wait until tomorrow to water them).
        </li>
        <li>
            Day 14: Pick up your copper axe. Plant the rest of your <a href="https://stardewvalleywiki.com/Strawberry_Seeds">strawberry seeds</a> and water them all.
        </li>
    </ul>
    <p>
        From Day 14 and on, you'll want to hit the mines as often as possible so you can to get plenty of <a href="https://stardewvalleywiki.com/Sprinkler">sprinklers</a>. You won't be
        able to craft them until you hit farming level
        6, but once you do things will get much easier. In my case, I was able to plant 288 <a href="https://stardewvalleywiki.com/Strawberry_Seeds">strawberry seeds</a> on Day 14,
        and by the end of spring they were 100% covered in
        <a href="https://stardewvalleywiki.com/Sprinkler">sprinklers</a>. And at the start of summer I was able to plant 768 <a href="https://stardewvalleywiki.com/Blueberry_Seeds">blueberry seeds</a>,
        with about half of them covered
        by <a href="https://stardewvalleywiki.com/Sprinkler">sprinklers</a> right from the beginning and all of them
        had <a href="https://stardewvalleywiki.com/Sprinkler">sprinklers</a> within a week or so.
    </p>

    <h2>Appendix and Useful Info</h2>
    <h3>Early Food Guide</h3>
    <p>
        Later in the game, I suggest buying <a href="https://stardewvalleywiki.com/Salad">salads</a> from
        <a href="https://stardewvalleywiki.com/The_Stardrop_Saloon">The Stardrop Saloon</a> since they are relatively high energy (they also make good gifts to most townspeople).
        But early in the game this is too expensive and you'll need to farm, find, or fish your food.
    </p>
    <p>
        The best food items are those that give you the most energy for the least cost. For example, an iridium-quality
        <a href="https://stardewvalleywiki.com/Bullhead">bullhead</a> will sell for $150 and give you 65 energy. But an iridium-quality
        <a href="https://stardewvalleywiki.com/Chub">chub</a> gives you the same amount of energy--65--but only sells for $100. If you have to choose
        (and make no mistake--you do!), it would be much better to eat the <a href="https://stardewvalleywiki.com/Chub">chub</a> and sell
        the <a href="https://stardewvalleywiki.com/Bullhead">bullhead</a> than the other way around.
    </p>
    <p>
        Higher quality items are almost always better to use as food. They are worth more than lower quality items, so you might think that means you should
        sell them, but they usually have an even higher increase in energy than they do in sale price.
    </p>
    <table>
        <tr>
            <th class="nobreak">
                Food
            </th>
            <th>
                Description
            </th>
        </tr>
        <tr>
            <td class="nobreak">
                <a href="https://stardewvalleywiki.com/Chub">Chub</a>
            </td>
            <td>
                Keep any iridium-quality ones you find (you will need more than you think). These will be your main food source in the first half of spring.
            </td>
        </tr>
        <tr>
            <td class="nobreak">
                <a href="https://stardewvalleywiki.com/Smallmouth_Bass">Smallmouth Bass</a>
            </td>
            <td>
                These have the same sale price and energy as chubs, so you should also keep any iridium-quality ones you find.
            </td>
        </tr>
        <tr>
            <td class="nobreak">
                <a href="https://stardewvalleywiki.com/Green_Algae">Green Algae</a>
            </td>
            <td>
                Eat these before chubs.
            </td>
        </tr>
        <tr>
            <td class="nobreak">
                <a href="https://stardewvalleywiki.com/Leek">Leek</a>
            </td>
            <td>
                Better for food than a chub, so if you find one, don't sell it. Instead, if you get hungry, and you feel that urge ... take a leek.
            </td>
        </tr>
        <tr>
            <td class="nobreak">
                <a href="https://stardewvalleywiki.com/Dandelion">Dandelion</a>
            </td>
            <td>
                Eat these before chubs.
            </td>
        </tr>
        <tr>
            <td class="nobreak">
                <a href="https://stardewvalleywiki.com/Parsnip">Parsnip</a>
            </td>
            <td>
                Eat these before chubs.
            </td>
        </tr>
        <tr>
            <td class="nobreak">
                <a href="https://stardewvalleywiki.com/Spring_Onion">Spring Onion</a>
            </td>
            <td>
                These are worth getting on Day 1 and using for food, but they are too far away to justify foraging after Day 1.
            </td>
        </tr>
        <tr>
            <td class="nobreak">
                <a href="https://stardewvalleywiki.com/Cave_Carrot">Cave Carrot</a>
            </td>
            <td>
                Eat these before chubs.
            </td>
        </tr>
        <tr>
            <td class="nobreak">
                <a href="https://stardewvalleywiki.com/Salad">Salad</a>
            </td>
            <td>
                If you are out of everything else and you have to buy food, this is probably your best bet.
            </td>
        </tr>
    </table>
    <br />

    <h3>
        TLDR
    </h3>
    <p>
        If all of the above is too wordy, here is the super-short version:
    </p>
    <ul>
        <li>
            Day 1: Make a chest. Buy 3 parsnips, and plant and water them along with your other 15. Plant some mixed seeds if you want. Keep edible forageables for food.
        </li>
        <li>
            Day 2: Pick up your fishig rod from Willy and go fishing at the mountain lake. Sell all of your fish at the end of the day, but keep iridium-quality chubs for food.
        </li>
        <li>
            Day 3: Buy the upgraded fishing rod from Willy and some bait. Go fishing in the river, making sure to reach level 5 fishing by the end of the day. Sell all your fish
            except for iridium-quality smallmouth bass, which you should keep for food.
        </li>
        <li>
            Day 4: Buy the backpack upgrade. Go fishing at the mountain lake and sell all your fish at the end of the day.
        </li>
        <li>
            Day 5: Harvest your parsnips and keep them for food. Go to the mines, bringing plenty of food. Try to get down to at least level 20 or 25, if not more.
        </li>
        <li>
            Day 6: Make 5 copper bars and upgrade your pickaxe to copper. Either buy a whole bunch of potato seeds and plant them, or plan to continue fishing and mining through Day 12.
        </li>
    </ul>

    <h2>
        Thanks
    </h2>
    <p>
        Thank you for reading this guide!
    </p>
    <p>
        If you have any suggestions or find any issues with it, please feel free to email me at robbie@autosaved.org.
    </p>
</template>


<style scoped lang="scss">
.image-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10%;
    text-align: center;
}

.image-description {
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
}

.h1-description {
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
}

h2 {
    margin-top: 30px;
}

.nobreak {
    white-space: nowrap;
    padding-right: 20px;
}

td {
    vertical-align: top;
}

th {
    vertical-align: top;
}
</style>