import { MaterialQuantity } from "./MaterialQuantity";

export class Utils {
	public static readonly BASICBELTSPEED = 15;
	public static readonly FASTBELTSPEED = 30;
	public static readonly EXPRESSBELTSPEED = 45;
	public static readonly PIPESPEED = 1000;

	public static RoundUpMostly(num: number): number {
		// I was using this to allow for num to be just barely more than a number and still get rounded to it, but it seems to make no practical difference (so far?):
		return Math.ceil(num - .0001);
	}

	public static ConvertToArray(materialQuantities: Record<string, MaterialQuantity>, ignoreZero: boolean): MaterialQuantity[] {
		const array: MaterialQuantity[] = [];
		for (const key in materialQuantities) {
			const materialQuantity = materialQuantities[key];
			if ((!ignoreZero) || (materialQuantity.NumPerSec !== 0)) {
				array.push(materialQuantity);
			}
		}
		return array;
	}
}