<template>
	<searchable-dropdown searchText="Search Materials" :items="searchItems" v-model="selectedSearchItem"></searchable-dropdown>
</template>

<script>
	import SearchableDropdown from "./SearchableDropdown.vue";

	export default {
		name: 'MaterialSelector',
		components: {
			'searchable-dropdown': SearchableDropdown,
		},
		props: {
			materials: {
				type: Array,
				required: true,
			},
			modelValue: {
				type: Object,
				required: true,
			}
		},
		computed: {
			searchItems: function () {
				let searchItems = [];
				for (let material of this.materials) {
					searchItems.push({
						material: material,
						name: material.Name,
						aliases: material.Aliases,
						imageUrl: material.ImageUrl,
					});
				}
				return searchItems;
			},
			selectedSearchItem: {
				get: function () {
					if (!this.modelValue) {
						return null;
					}
					for (let searchItem of this.searchItems) {
						if (searchItem.material === this.modelValue) {
							return searchItem;
						}
					}
					return null;
				},
				set: function (newValue) {
					this.$emit('update:modelValue', newValue?.material);
				},
			},
		},
	}
</script>

<style scoped>
</style>