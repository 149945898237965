import { Material } from "./Material";

export class Part {
	public readonly Material: Material;
	public readonly NumRequired: number;

	public constructor(material: Material, numRequired: number) {
		this.Material = material;
		this.NumRequired = numRequired;
	}
}