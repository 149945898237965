export class Producer {
	public static readonly BurnerMiningDrill = new Producer("Burner Mining Drill", "miners", 0.5);
	public static readonly ElectricMiningDrill = new Producer("Electric Mining Drill", "miners", 1);

	public static readonly Pumpjack = new Producer("Pumpjack", "pumpjacks", 1);

	public static readonly OffshorePump = new Producer("Offshore Pump", "pumps", 1);

	public static readonly StoneFurnace = new Producer("Stone Furnace", "furnaces", 0.5);
	public static readonly SteelFurnace = new Producer("Steel Furnace", "furnaces", 1);
	public static readonly ElectricFurnace = new Producer("Electric Furnace", "furnaces", 1);

	public static readonly AssemblingMachine1 = new Producer("Assembling Machine 1", "assembling machines", 0.5);
	public static readonly AssemblingMachine2 = new Producer("Assembling Machine 2", "assembling machines", 0.75);
	public static readonly AssemblingMachine3 = new Producer("Assembling Machine 3", "assembling machines", 1.25);

	public static readonly OilRefinery = new Producer("Oil Refinery", "refineries", 1);

	public static readonly ChemicalPlant = new Producer("Chemical Plant", "chemical plants", 1);

	public static readonly Centrifuge = new Producer("Centrifuge", "centrifuges", 1);

	public static readonly RocketSilo = new Producer("Rocket Silo", "rocket silos", 1);

	public static readonly NuclearReactor = new Producer("Nuclear Reactor", "nuclear reactors", 1);

	public readonly Name: string;
	public readonly PluralName: string;
	public readonly Speed: number;

	private constructor(name: string, pluralName: string, speed: number) {
		this.Name = name;
		this.PluralName = pluralName;
		this.Speed = speed;
	}
}