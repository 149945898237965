import { Material } from "./Material";
import { ProducerProfile } from "./ProducerProfile";
import { Utils } from "./Utils";

export class MaterialQuantity {
	public PreReqs: MaterialQuantity[];
	public NumPerSec: number;
	public Material: Material;

	public get NumBasicBelts(): number {
		return Utils.RoundUpMostly(this.NumPerSec * 2 / Utils.BASICBELTSPEED) / 2.0;
	}

	public get NumFastBelts(): number {
		return Utils.RoundUpMostly(this.NumPerSec * 2 / Utils.FASTBELTSPEED) / 2.0;
	}

	public get NumExpressBelts(): number {
		return Utils.RoundUpMostly(this.NumPerSec * 2 / Utils.EXPRESSBELTSPEED) / 2.0;
	}

	public get NumPipes(): number {
		return Utils.RoundUpMostly(this.NumPerSec / Utils.PIPESPEED);
	}

	public constructor(material: Material, numPerSec: number) {
		this.Material = material;
		this.NumPerSec = numPerSec;
		this.PreReqs = []; // Unnecessary because it happens in the next line, but getting around nit-picky Typescript compiler error
		this.UpdatePreReqs();
	}

	public GetNumFactories(producerProfile: ProducerProfile): number {
		return Utils.RoundUpMostly(this.NumPerSec / ((this.Material.NumCrafted * this.Material.GetProducer(producerProfile).Speed) / this.Material.CraftTime));
	}

	public GetInputQuantities(inputs: Material[]): MaterialQuantity[] {
		const inputQuantities: Record<string, MaterialQuantity> = {};
		this.AddToInputQuantities(inputQuantities, inputs);
		return Utils.ConvertToArray(inputQuantities, true);
	}

	private AddToInputQuantities(inputQuantities: Record<string, MaterialQuantity>, inputs: Material[]): void {
		if (inputs.indexOf(this.Material) !== -1) {
			if (inputQuantities[this.Material.Name]) {
				inputQuantities[this.Material.Name].AddNumPerSec(this.NumPerSec);
			}
			else {
				inputQuantities[this.Material.Name] = new MaterialQuantity(this.Material, this.NumPerSec);
			}
		}
		else {
			for (const quantity of this.PreReqs) {
				quantity.AddToInputQuantities(inputQuantities, inputs);
			}
		}
	}

	public AddNumPerSec(numPerSec: number): void {
		this.NumPerSec += numPerSec;
		this.UpdatePreReqs();
	}

	private UpdatePreReqs(): void {
		this.PreReqs = [];
		for (const part of this.Material.Parts) {
			this.PreReqs.push(new MaterialQuantity(part.Material, part.NumRequired * (this.NumPerSec / this.Material.NumCrafted)));
		}
	}
}